// @flow
import {
    PAY_LIST,
    PAYROLL_LIST_SUCCESS,
    PAYROLL_LIST_FAILED,
    PAYROLL_ADD,
    PAYROLL_ADD_SUCCESS,
    PAYROLL_ADD_FAILED,
    PAYROLL_UPDATE,
    PAYROLL_UPDATE_SUCCESS,
    PAYROLL_UPDATE_FAILED,
    PAYROLL_DELETE,
    PAYROLL_DELETE_SUCCESS,
    PAYROLL_DELETE_FAILED,
    PAYROLL_EMPLOYEE,
    PAYROLL_EMPLOYEE_LIST_SUCCESS,
    PAYROLL_EMPLOYEE_LIST_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type PayrollAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Payroll = (state: State = INIT_STATE, action: PayrollAction) => {
    switch (action.type) {
        case PAY_LIST:
            return { ...state, listloading: true };
        case PAYROLL_LIST_SUCCESS:
            return { ...state, Payroll: action.payload, listloading: false, error: null };
        case PAYROLL_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAYROLL_ADD:
            return { ...state, loading: true };
        case PAYROLL_ADD_SUCCESS:
            return { ...state, PayrollAdd: action.payload, loading: false, error: null };
        case PAYROLL_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAYROLL_UPDATE:
            return { ...state, loading: true };
        case PAYROLL_UPDATE_SUCCESS:
            return { ...state, PayrollUpdate: action.payload, loading: false, error: null };
        case PAYROLL_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAYROLL_DELETE:
            return { ...state, loading: true };
        case PAYROLL_DELETE_SUCCESS:
            return { ...state, PayrollDelete: action.payload, loading: false, error: null };
        case PAYROLL_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };

            case PAYROLL_EMPLOYEE:
                return { ...state, listloading: false };
            case PAYROLL_EMPLOYEE_LIST_SUCCESS:
                return { ...state, EmpData: action.payload, listloading: false, error: null };
            case PAYROLL_EMPLOYEE_LIST_FAILED:
                return { ...state, error: action.payload, loading: false };


        default:
            return { ...state };
    }
};

export default Payroll;
