// @flow
import {
    DEPARTMENT_LIST,
    DEPARTMENT_LIST_SUCCESS,
    DEPARTMENT_LIST_FAILED,
    DEPARTMENT_ADD,
    DEPARTMENT_ADD_SUCCESS,
    DEPARTMENT_ADD_FAILED,
    DEPARTMENT_UPDATE,
    DEPARTMENT_UPDATE_SUCCESS,
    DEPARTMENT_UPDATE_FAILED,
    DEPARTMENT_DELETE,
    DEPARTMENT_DELETE_SUCCESS,
    DEPARTMENT_DELETE_FAILED,
    DAILYACTIVITY_LIST,
    DAILYACTIVITY_LIST_SUCCESS,
    DAILYACTIVITY_LIST_FAILED,
    DAILYACTIVITY_ADD,
    DAILYACTIVITY_ADD_SUCCESS,
    DAILYACTIVITY_ADD_FAILED,
    DAILYJOBDES_ADD,
    DAILYJOBDES_ADD_SUCCESS,
    DAILYJOBDES_ADD_FAILED,
    DAILYACTIVITY_UPDATE,
    DAILYACTIVITY_UPDATE_SUCCESS,
    DAILYACTIVITY_UPDATE_FAILED,
    DAILYACTIVITY_DELETE,
    DAILYACTIVITY_DELETE_SUCCESS,
    DAILYACTIVITY_DELETE_FAILED,
    WEEKLYACTIVITY_LIST,
    WEEKLYACTIVITY_LIST_SUCCESS,
    WEEKLYACTIVITY_LIST_FAILED,
    WEEKLYACTIVITY_ADD,
    WEEKLYACTIVITY_ADD_SUCCESS,
    WEEKLYACTIVITY_ADD_FAILED,
    WEEKLYJOBDES_ADD,
    WEEKLYJOBDES_ADD_SUCCESS,
    WEEKLYJOBDES_ADD_FAILED,
    WEEKLYACTIVITY_UPDATE,
    WEEKLYACTIVITY_UPDATE_SUCCESS,
    WEEKLYACTIVITY_UPDATE_FAILED,
    WEEKLYACTIVITY_DELETE,
    WEEKLYACTIVITY_DELETE_SUCCESS,
    WEEKLYACTIVITY_DELETE_FAILED,
    MONTHLYACTIVITY_LIST,
    MONTHLYACTIVITY_LIST_SUCCESS,
    MONTHLYACTIVITY_LIST_FAILED,
    MONTHLYACTIVITY_ADD,
    MONTHLYACTIVITY_ADD_SUCCESS,
    MONTHLYACTIVITY_ADD_FAILED,
    MONTHLYJOBDES_ADD,
    MONTHLYJOBDES_ADD_SUCCESS,
    MONTHLYJOBDES_ADD_FAILED,
    MONTHLYACTIVITY_UPDATE,
    MONTHLYACTIVITY_UPDATE_SUCCESS,
    MONTHLYACTIVITY_UPDATE_FAILED,
    MONTHLYACTIVITY_DELETE,
    MONTHLYACTIVITY_DELETE_SUCCESS,
    MONTHLYACTIVITY_DELETE_FAILED,
    USERDEPARTMENT_LIST,
    USERDEPARTMENT_LIST_SUCCESS,
    USERDEPARTMENT_LIST_FAILED,
    USERTEAM_LIST,
    USERTEAM_LIST_SUCCESS,
    USERTEAM_LIST_FAILED,
} from './constants';

type DepartmentAction = { type: string, payload: {} | string };

export const getDepartmentList = (): DepartmentAction => ({
    type: DEPARTMENT_LIST,
    payload: {},
});

export const getDepartmentListSuccess = (department: string): DepartmentAction => ({
    type: DEPARTMENT_LIST_SUCCESS,
    payload: department,
});

export const getDepartmentListFailed = (error: string): DepartmentAction => ({
    type: DEPARTMENT_LIST_FAILED,
    payload: error,
});

export const getDepartmentAdd = (data:{}): DepartmentAction => ({
    type: DEPARTMENT_ADD,
    payload: data,
});

export const getDepartmentAddSuccess = (departmentAdd: string): DepartmentAction => ({
    type: DEPARTMENT_ADD_SUCCESS,
    payload: departmentAdd,
});

export const getDepartmentAddFailed = (error: string): DepartmentAction => ({
    type: DEPARTMENT_ADD_FAILED,
    payload: error,
});

export const getDepartmentUpdate = (data:{}): DepartmentAction => ({
    type: DEPARTMENT_UPDATE,
    payload: data,
});

export const getDepartmentUpdateSuccess = (departmentUpdate: string): DepartmentAction => ({
    type: DEPARTMENT_UPDATE_SUCCESS,
    payload: departmentUpdate,
});

export const getDepartmentUpdateFailed = (error: string): DepartmentAction => ({
    type: DEPARTMENT_UPDATE_FAILED,
    payload: error,
});

export const getDepartmentDelete = (id): DepartmentAction => ({
    type: DEPARTMENT_DELETE,
    payload: id,
});

export const getDepartmentDeleteSuccess = (departmentDelete: string): DepartmentAction => ({
    type: DEPARTMENT_DELETE_SUCCESS,
    payload: departmentDelete,
});

export const getDepartmentDeleteFailed = (error: string): DepartmentAction => ({
    type: DEPARTMENT_DELETE_FAILED,
    payload: error,
});
export const getDailyactivityList = (data:{}): DepartmentAction => ({
    type: DAILYACTIVITY_LIST,
    payload: data,
});

export const getDailyactivityListSuccess = (dailyactivity: string): DepartmentAction => ({
    type: DAILYACTIVITY_LIST_SUCCESS,
    payload: dailyactivity,
});

export const getDailyactivityListFailed = (error: string): DepartmentAction => ({
    type: DAILYACTIVITY_LIST_FAILED,
    payload: error,
});

export const getDailyactivityAdd = (data:{}): DepartmentAction => ({
    type: DAILYACTIVITY_ADD,
    payload: data,
});

export const getDailyactivityAddSuccess = (dailyactivityAdd: string): DepartmentAction => ({
    type: DAILYACTIVITY_ADD_SUCCESS,
    payload: dailyactivityAdd,
});

export const getDailyactivityAddFailed = (error: string): DepartmentAction => ({
    type: DAILYACTIVITY_ADD_FAILED,
    payload: error,
});


export const getDailyjobdesAdd = (data:{}): DepartmentAction => ({
    type: DAILYJOBDES_ADD,
    payload: data,
});

export const getDailyjobdesAddSuccess = (dailyjobdesAdd: string): DepartmentAction => ({
    type: DAILYJOBDES_ADD_SUCCESS,
    payload: dailyjobdesAdd,
});

export const getDailyjobdesAddFailed = (error: string): DepartmentAction => ({
    type: DAILYJOBDES_ADD_FAILED,
    payload: error,
});




export const getDailyactivityUpdate = (data:{}): DepartmentAction => ({
    type: DAILYACTIVITY_UPDATE,
    payload: data,
});

export const getDailyactivityUpdateSuccess = (dailyactivityUpdate: string): DepartmentAction => ({
    type: DAILYACTIVITY_UPDATE_SUCCESS,
    payload: dailyactivityUpdate,
});

export const getDailyactivityUpdateFailed = (error: string): DepartmentAction => ({
    type: DAILYACTIVITY_UPDATE_FAILED,
    payload: error,
});

export const getDailyactivityDelete = (id): DepartmentAction => ({
    type: DAILYACTIVITY_DELETE,
    payload: id,
});

export const getDailyactivityDeleteSuccess = (dailyactivityDelete: string): DepartmentAction => ({
    type: DAILYACTIVITY_DELETE_SUCCESS,
    payload: dailyactivityDelete,
});

export const getDailyactivityDeleteFailed = (error: string): DepartmentAction => ({
    type: DAILYACTIVITY_DELETE_FAILED,
    payload: error,
});

export const getWeeklyactivityList = (data:{}): DepartmentAction => ({
    type: WEEKLYACTIVITY_LIST,
    payload: data,
});

export const getWeeklyactivityListSuccess = (weeklyactivity: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_LIST_SUCCESS,
    payload: weeklyactivity,
});

export const getWeeklyactivityListFailed = (error: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_LIST_FAILED,
    payload: error,
});

export const getWeeklyactivityAdd = (data:{}): DepartmentAction => ({
    type: WEEKLYACTIVITY_ADD,
    payload: data,
});

export const getWeeklyactivityAddSuccess = (weeklyactivityAdd: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_ADD_SUCCESS,
    payload: weeklyactivityAdd,
});

export const getWeeklyactivityAddFailed = (error: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_ADD_FAILED,
    payload: error,
});


export const getWeeklyjobdesAdd = (data:{}): DepartmentAction => ({
    type: WEEKLYJOBDES_ADD,
    payload: data,
});

export const getWeeklyjobdesAddSuccess = (weeklyjobdesAdd: string): DepartmentAction => ({
    type: WEEKLYJOBDES_ADD_SUCCESS,
    payload: weeklyjobdesAdd,
});

export const getWeeklyjobdesAddFailed = (error: string): DepartmentAction => ({
    type: WEEKLYJOBDES_ADD_FAILED,
    payload: error,
});




export const getWeeklyactivityUpdate = (data:{}): DepartmentAction => ({
    type: WEEKLYACTIVITY_UPDATE,
    payload: data,
});

export const getWeeklyactivityUpdateSuccess = (weeklyactivityUpdate: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_UPDATE_SUCCESS,
    payload: weeklyactivityUpdate,
});

export const getWeeklyactivityUpdateFailed = (error: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_UPDATE_FAILED,
    payload: error,
});

export const getWeeklyactivityDelete = (id): DepartmentAction => ({
    type: WEEKLYACTIVITY_DELETE,
    payload: id,
});

export const getWeeklyactivityDeleteSuccess = (weeklyactivityDelete: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_DELETE_SUCCESS,
    payload: weeklyactivityDelete,
});

export const getWeeklyactivityDeleteFailed = (error: string): DepartmentAction => ({
    type: WEEKLYACTIVITY_DELETE_FAILED,
    payload: error,
});

export const getMonthlyactivityList = (data:{}): DepartmentAction => ({
    type: MONTHLYACTIVITY_LIST,
    payload: data,
});

export const getMonthlyactivityListSuccess = (monthlyactivity: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_LIST_SUCCESS,
    payload: monthlyactivity,
});

export const getMonthlyactivityListFailed = (error: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_LIST_FAILED,
    payload: error,
});

export const getMonthlyactivityAdd = (data:{}): DepartmentAction => ({
    type: MONTHLYACTIVITY_ADD,
    payload: data,
});

export const getMonthlyactivityAddSuccess = (monthlyactivityAdd: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_ADD_SUCCESS,
    payload: monthlyactivityAdd,
});

export const getMonthlyactivityAddFailed = (error: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_ADD_FAILED,
    payload: error,
});


export const getMonthlyjobdesAdd = (data:{}): DepartmentAction => ({
    type: MONTHLYJOBDES_ADD,
    payload: data,
});

export const getMonthlyjobdesAddSuccess = (monthlyjobdesAdd: string): DepartmentAction => ({
    type: MONTHLYJOBDES_ADD_SUCCESS,
    payload: monthlyjobdesAdd,
});

export const getMonthlyjobdesAddFailed = (error: string): DepartmentAction => ({
    type: MONTHLYJOBDES_ADD_FAILED,
    payload: error,
});




export const getMonthlyactivityUpdate = (data:{}): DepartmentAction => ({
    type: MONTHLYACTIVITY_UPDATE,
    payload: data,
});

export const getMonthlyactivityUpdateSuccess = (monthlyactivityUpdate: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_UPDATE_SUCCESS,
    payload: monthlyactivityUpdate,
});

export const getMonthlyactivityUpdateFailed = (error: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_UPDATE_FAILED,
    payload: error,
});

export const getMonthlyactivityDelete = (id): DepartmentAction => ({
    type: MONTHLYACTIVITY_DELETE,
    payload: id,
});

export const getMonthlyactivityDeleteSuccess = (monthlyactivityDelete: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_DELETE_SUCCESS,
    payload: monthlyactivityDelete,
});

export const getMonthlyactivityDeleteFailed = (error: string): DepartmentAction => ({
    type: MONTHLYACTIVITY_DELETE_FAILED,
    payload: error,
});
//USER DEPT


export const getUserDepartmentList = (): DepartmentAction => ({
    type: USERDEPARTMENT_LIST,
    payload: {},
});

export const getUserDepartmentListSuccess = (userdepartment: string): DepartmentAction => ({
    type: USERDEPARTMENT_LIST_SUCCESS,
    payload: userdepartment,
});

export const getUserDepartmentListFailed = (error: string): DepartmentAction => ({
    type: USERDEPARTMENT_LIST_FAILED,
    payload: error,
});


export const getUserTeamList = (): DepartmentAction => ({
    type: USERTEAM_LIST,
    payload: {},
});

export const getUserTeamListSuccess = (userteam: string): DepartmentAction => ({
    type: USERTEAM_LIST_SUCCESS,
    payload: userteam,
});

export const getUserTeamListFailed = (error: string): DepartmentAction => ({
    type: USERTEAM_LIST_FAILED,
    payload: error,
});