// @flow
import {
    REPORTTEAMMONTH_LIST,
    REPORTTEAMMONTH_LIST_SUCCESS,
    REPORTTEAMMONTH_LIST_FAILED,
    REPORT_TEAM_LIST,
    REPORT_TEAM_LIST_SUCCESS,
    REPORT_TEAM_LIST_FAILED,
    TEAM_MEMBERS_REPORT_LIST,
    TEAM_MEMBERS_REPORT_LIST_SUCCESS,
    TEAM_MEMBERS_REPORT_LIST_FAILED,

} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type TeamReportAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const TeamReportMonthly = (state: State = INIT_STATE, action: TeamReportAction) => {
   
    switch (action.type) {
        case TEAM_MEMBERS_REPORT_LIST:
            return { ...state, listloading: true };
          case TEAM_MEMBERS_REPORT_LIST_SUCCESS:
            return {
              ...state,
              teamlister: action.payload,
              listloading: false,
              error: null,
            };
          case TEAM_MEMBERS_REPORT_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REPORTTEAMMONTH_LIST:
            return { ...state, listloading: true };
        case REPORTTEAMMONTH_LIST_SUCCESS:
            return { ...state, teamreportmonth: action.payload, listloading: false, error: null };
        case REPORTTEAMMONTH_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
      
            case REPORT_TEAM_LIST:
            return { ...state, listloading: true };
            case REPORT_TEAM_LIST_SUCCESS:
                return { ...state, reportdata: action.payload, listloading: false, error: null };
            case REPORT_TEAM_LIST_FAILED:
                return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default TeamReportMonthly;
