// @flow
import {
    REPORTTEAMMONTH_LIST,
    REPORTTEAMMONTH_LIST_SUCCESS,
    REPORTTEAMMONTH_LIST_FAILED,
    REPORT_TEAM_LIST,
    REPORT_TEAM_LIST_SUCCESS,
    REPORT_TEAM_LIST_FAILED,
    TEAM_MEMBERS_REPORT_LIST,
    TEAM_MEMBERS_REPORT_LIST_SUCCESS,
    TEAM_MEMBERS_REPORT_LIST_FAILED,

} from './constants';

type TeamReportAction = { type: string, payload: {} | string };

export const getTeamReportMembersList = (id): TeamReportAction => ({
    type: TEAM_MEMBERS_REPORT_LIST,
    payload: id,
  });
  
  export const getTeamReportMembersListSuccess = (team: string): TeamReportAction => ({
    type: TEAM_MEMBERS_REPORT_LIST_SUCCESS,
    payload: team,
  });
  
  export const getTeamReportMembersListFailed = (error: string): TeamReportAction => ({
    type: TEAM_MEMBERS_REPORT_LIST_FAILED,
    payload: error,
  });
  

export const getTeamReportMonthList = (data:{}): TeamReportAction => ({
    type: REPORTTEAMMONTH_LIST,
    payload: data,
});

export const getTeamReportMonthListSuccess = (reportmonth: string): TeamReportAction => ({
    type:REPORTTEAMMONTH_LIST_SUCCESS,
    payload: reportmonth,
});

export const getTeamReportMonthListFailed = (error: string): TeamReportAction => ({
    type: REPORTTEAMMONTH_LIST_FAILED,
    payload: error,
});

export const getTeamReportList = (data:{}): TeamReportAction => ({
    type:REPORT_TEAM_LIST,
    payload: data,
});

export const getTeamReportListSuccess = (reportmonth: string): TeamReportAction => ({
    type:REPORT_TEAM_LIST_SUCCESS,
    payload: reportmonth,
});

export const getTeamReportListFailed = (error: string): TeamReportAction => ({
    type: REPORT_TEAM_LIST_FAILED,
    payload: error,
});
