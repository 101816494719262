// @flow
import {
    MONTHLYACTIVITYDATA_LIST,
    MONTHLYACTIVITYDATA_LIST_SUCCESS,
    MONTHLYACTIVITYDATA_LIST_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type MonthlyactivitydataAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Monthlyactivitydata = (state: State = INIT_STATE, action: MonthlyactivitydataAction) => {
    switch (action.type) {
        case MONTHLYACTIVITYDATA_LIST:
            return { ...state, listloading: true };
        case MONTHLYACTIVITYDATA_LIST_SUCCESS:
            return { ...state, monthlyactivitydata: action.payload, listloading: false, error: null };
        case MONTHLYACTIVITYDATA_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
 

   
        default:
            return { ...state };
    }
};

export default Monthlyactivitydata;
