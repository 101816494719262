// @flow
import {
    REMAINDER_LIST,
    REMAINDER_LIST_SUCCESS,
    REMAINDER_LIST_FAILED,
    REMAINDER_ADD,
    REMAINDER_ADD_SUCCESS,
    REMAINDER_ADD_FAILED,
    REMAINDER_UPDATE,
    REMAINDER_UPDATE_SUCCESS,
    REMAINDER_UPDATE_FAILED,
    REMAINDER_DELETE,
    REMAINDER_DELETE_SUCCESS,
    REMAINDER_DELETE_FAILED
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type RemainderAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const RemainderList = (state: State = INIT_STATE, action: RemainderAction) => {
    switch (action.type) {
        case   REMAINDER_LIST:
            return { ...state, listloading: true };
        case   REMAINDER_LIST_SUCCESS:
            return { ...state, remainder: action.payload, listloading: false, error: null };
        case   REMAINDER_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case   REMAINDER_ADD:
            return { ...state, loading: true };
        case   REMAINDER_ADD_SUCCESS:
            return { ...state, remainderAdd: action.payload, loading: false, error: null };
        case   REMAINDER_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case   REMAINDER_UPDATE:
            return { ...state, loading: true };
        case   REMAINDER_UPDATE_SUCCESS:
            return { ...state, remainderUpdate: action.payload, loading: false, error: null };
        case   REMAINDER_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case   REMAINDER_DELETE:
            return { ...state, loading: true };
        case   REMAINDER_DELETE_SUCCESS:
            return { ...state, remainderDelete: action.payload, loading: false, error: null };
        case   REMAINDER_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default RemainderList;
