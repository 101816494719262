// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { PAY_DETAILS_LIST ,PAY_DETAILS_ADD,PAY_DETAILS_DELETE,   PAY_UPDATE,
    PAY_UPDATE_SUCCESS,
    PAY_UPDATE_FAILED,SALARY_DETAILS_FETCH,
    SALARY_DETAILS_FETCH_SUCCESS,
    SALARY_DETAILS_FETCH_FAILED,} from './constants';

import {
    getPayDetailsListDataSuccess,
    getPayDetailsListDataFailed,
    getPayAddSuccess,
    getPayAddFailed,
    getPayDeleteSuccess,
    getPayDeleteFailed,
    getPayUpdateSuccess,
    getPayUpdateFailed,
    getFetchSalarySuccess,
    getFetchSalaryFailed,
    getFetchSalary
  
} from './actions';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoggedInUser } from '../../helpers/authUtils';

const PayAddedSucsess = () => toast.success('payslip generated Successfully', { transition: Zoom });
const PayDeletedSuccess = () => toast.success('payslip Deleted Successfully', { transition: Zoom });
const PayUpdated = () => toast.info('payslip Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please Fill All Fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* PayDetailsDataList({ payload: data }) {
    const user = getLoggedInUser();
 
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.PayrollDetailsListData,
        data: {

            "id":data.param_id,
          

         }
    };
    try {
        const response = yield call(ApiCall, options);

        yield put(getPayDetailsListDataSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayDetailsListDataFailed(message));
    }
}
// Payroll Add

function* PayAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.PayrollDetailsDataAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        PayAddedSucsess();
        yield put(getPayAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayAddFailed(message));
    }
}
function* PayDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.PayrollDetailsDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        PayDeletedSuccess();
        yield put(getPayDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayDeleteFailed(message));
    }
}


function* PayUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.PayrollDetailsUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        PayUpdated();
        yield put(getPayUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayUpdateFailed(message));
    }
}

//salary fetch

// Payroll Add

function* salaryFetch({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.SalaryDetailsFetch,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        
        yield put(getFetchSalarySuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getFetchSalaryFailed(message));
    }
}



export function* watchPayrollDetailsListData(): any {
    yield takeEvery(PAY_DETAILS_LIST, PayDetailsDataList);
}
export function* watchPayAdd(): any {
    yield takeEvery(PAY_DETAILS_ADD, PayAdd);
}
export function* watchPayDelete(): any {
    yield takeEvery(PAY_DETAILS_DELETE, PayDelete);
}

export function* watchPayUpdate(): any {
    yield takeEvery(PAY_UPDATE, PayUpdate);
}
export function* watchSalaryFetch(): any {
    yield takeEvery(SALARY_DETAILS_FETCH, salaryFetch);
}

function* authSaga(): any {
    yield all([
     
        fork(watchPayrollDetailsListData),
        fork(watchPayAdd),
        fork(watchPayDelete),
        fork(watchPayUpdate),
        fork(watchSalaryFetch),
    ]);

}

export default authSaga;
