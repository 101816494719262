// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { ADMIN_LIST, ADMIN_ADD, ADMIN_UPDATE, ADMIN_DELETE } from './constants';

import {
    getAdminListSuccess,
    getAdminListFailed,
    getAdminAddSuccess,
    getAdminAddFailed,
    getAdminUpdateSuccess,
    getAdminUpdateFailed,
    getAdminDeleteSuccess,
    getAdminDeleteFailed,
} from './actions';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoggedInUser } from '../../helpers/authUtils';

const AdminAddedSucsess = () => toast.success('Admin Added Successfully', { transition: Zoom });
const AdminDeletedSuccess = () => toast.success('Admin Deleted Successfully', { transition: Zoom });
const AdminUpdated = () => toast.info('Admin Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please Fill All Fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* AdminList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.AdminList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getAdminListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminListFailed(message));
    }
}

// Admin Add

function* AdminAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.AdminAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        AdminAddedSucsess();
        yield put(getAdminAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminAddFailed(message));
    }
}

// Admin Update

function* AdminUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.AdminUpdate + '/' + (data && data.admin_id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        AdminUpdated();
        yield put(getAdminUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminUpdateFailed(message));
    }
}

// Admin Delete

function* AdminDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.AdminDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        AdminDeletedSuccess();
        yield put(getAdminDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminDeleteFailed(message));
    }
}

export function* watchAdminList(): any {
    yield takeEvery(ADMIN_LIST, AdminList);
}
export function* watchAdminAdd(): any {
    yield takeEvery(ADMIN_ADD, AdminAdd);
}
export function* watchAdminUpdate(): any {
    yield takeEvery(ADMIN_UPDATE, AdminUpdate);
}
export function* watchAdminDelete(): any {
    yield takeEvery(ADMIN_DELETE, AdminDelete);
}

function* authSaga(): any {
    yield all([
        fork(watchAdminList),
        fork(watchAdminAdd),
        fork(watchAdminUpdate),
        fork(watchAdminDelete),
    ]);
}

export default authSaga;
