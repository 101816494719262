// @flow
import {
    PAY_LIST,
    PAYROLL_LIST_SUCCESS,
    PAYROLL_LIST_FAILED,
    PAYROLL_ADD,
    PAYROLL_ADD_SUCCESS,
    PAYROLL_ADD_FAILED,
    PAYROLL_UPDATE,
    PAYROLL_UPDATE_SUCCESS,
    PAYROLL_UPDATE_FAILED,
    PAYROLL_DELETE,
    PAYROLL_DELETE_SUCCESS,
    PAYROLL_DELETE_FAILED,
    PAYROLL_EMPLOYEE,
    PAYROLL_EMPLOYEE_LIST_SUCCESS,
    PAYROLL_EMPLOYEE_LIST_FAILED,

} from './constants';

type PayrollAction = { type: string, payload: {} | string };

export const getPayrollList = (): PayrollAction => ({
    type: PAY_LIST,
    payload: {},
});

export const getPayrollListSuccess = (Payroll: string): PayrollAction => ({
    type: PAYROLL_LIST_SUCCESS,
    payload: Payroll,
});

export const getPayrollListFailed = (error: string): PayrollAction => ({
    type: PAYROLL_LIST_FAILED,
    payload: error,
});

export const getPayrollAdd = (data:{}): PayrollAction => ({
    type: PAYROLL_ADD,
    payload: data,
});

export const getPayrollAddSuccess = (PayrollAdd: string): PayrollAction => ({
    type: PAYROLL_ADD_SUCCESS,
    payload: PayrollAdd,
});

export const getPayrollAddFailed = (error: string): PayrollAction => ({
    type: PAYROLL_ADD_FAILED,
    payload: error,
});

export const getPayrollUpdate = (data:{}): PayrollAction => ({
    type: PAYROLL_UPDATE,
    payload: data,
});

export const getPayrollUpdateSuccess = (PayrollUpdate: string): PayrollAction => ({
    type: PAYROLL_UPDATE_SUCCESS,
    payload: PayrollUpdate,
});

export const getPayrollUpdateFailed = (error: string): PayrollAction => ({
    type:PAYROLL_UPDATE_FAILED,
    payload: error,
});

export const getPayrollDelete = (id): PayrollAction => ({
    type: PAYROLL_DELETE,
    payload: id,
});

export const getPayrollDeleteSuccess = (PayrollDelete: string): PayrollAction => ({
    type: PAYROLL_DELETE_SUCCESS,
    payload: PayrollDelete,
});

export const getPayrollDeleteFailed = (error: string): PayrollAction => ({
    type: PAYROLL_DELETE_FAILED,
    payload: error,
});


export const getPayrollEmployee= (): PayrollAction => ({
    type: PAYROLL_EMPLOYEE,
    payload:{},
});

export const getPayrollEmployeeListSuccess = (EmpData: string): PayrollAction => ({
    type: PAYROLL_EMPLOYEE_LIST_SUCCESS,
    payload: EmpData,
});

export const getPayrollEmployeeListFailed = (error: string): PayrollAction => ({
    type: PAYROLL_EMPLOYEE_LIST_FAILED,
    payload: error,
});