/* DESIGNATION */
export const ADMIN_LIST = 'ADMIN_LIST';
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS';
export const ADMIN_LIST_FAILED = 'ADMIN_LIST_FAILED';

export const ADMIN_ADD = 'ADMIN_ADD';
export const ADMIN_ADD_SUCCESS = 'ADMIN_ADD_SUCCESS';
export const ADMIN_ADD_FAILED = 'DESIGNATIONADD_ADD_FAILED';

export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const ADMIN_UPDATE_SUCCESS = 'ADMIN_UPDATE_SUCCESS';
export const ADMIN_UPDATE_FAILED = 'ADMIN_UPDATE_FAILED';

export const ADMIN_DELETE = 'ADMIN_DELETE';
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS';
export const ADMIN_DELETE_FAILED = 'DESIGNATIONADD_DELETE_FAILED';
