// var url = '';
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     url = "http://127.0.0.1:8000";
// } else {
//     url = "http://127.0.0.1:8000";
// }

// export const API_BASE_URL = url;





// var url = '';
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     url = "http://pe.localhost";
// } else {
//     url = "http://pe.localhost";
// }

// export const API_BASE_URL = url;





var url = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    url = "https://one.hashroot.com/server";
} else {
    url = "https://one.hashroot.com/server";
}

export const API_BASE_URL = url;
