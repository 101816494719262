// @flow
import {
    ADMINHASH_LIST,
    ADMINHASH_LIST_SUCCESS,
    ADMINHASH_LIST_FAILED,
    ADMINHASH_ADD,
    ADMINHASH_ADD_SUCCESS,
    ADMINHASH_ADD_FAILED,
    ADMINHASH_UPDATE,
    ADMINHASH_UPDATE_SUCCESS,
    ADMINHASH_UPDATE_FAILED,
    ADMINHASH_DELETE,
    ADMINHASH_DELETE_SUCCESS,
    ADMINHASH_DELETE_FAILED,
    ADMINHASH_SHOW,
    ADMINHASH_SHOW_SUCCESS,
    ADMINHASH_SHOW_FAILED,


    TOPICHASH_LIST,
    TOPICHASH_LIST_SUCCESS,
    TOPICHASH_LIST_FAILED,
    TOPICHASH_ADD,
    TOPICHASH_ADD_SUCCESS,
    TOPICHASH_ADD_FAILED,
    TOPICHASH_UPDATE,
    TOPICHASH_UPDATE_SUCCESS,
    TOPICHASH_UPDATE_FAILED,
    TOPICHASH_DELETE,
    TOPICHASH_DELETE_SUCCESS,
    TOPICHASH_DELETE_FAILED,
    TOPICHASH_SHOW,
    TOPICHASH_SHOW_SUCCESS,
    TOPICHASH_SHOW_FAILED,
    TOPICHASH_LOCK,
    TOPICHASH_LOCK_SUCCESS,
    TOPICHASH_LOCK_FAILED,
    TOPICHASH_UNLOCK,
    TOPICHASH_UNLOCK_SUCCESS,
    TOPICHASH_UNLOCK_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type AdminhashAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Adminhash = (state: State = INIT_STATE, action:AdminhashAction) => {
    switch (action.type) {
        case ADMINHASH_LIST:
            return { ...state, listloading: true };
        case ADMINHASH_LIST_SUCCESS:
            return { ...state, Adminhash: action.payload, listloading: false, error: null };
        case ADMINHASH_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADMINHASH_ADD:
            return { ...state, loading: true };
        case ADMINHASH_ADD_SUCCESS:
            return { ...state, AdminhashAdd: action.payload, loading: false, error: null };
        case ADMINHASH_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADMINHASH_UPDATE:
            return { ...state, loading: true };
        case ADMINHASH_UPDATE_SUCCESS:
            return { ...state, AdminhashUpdate: action.payload, loading: false, error: null };
        case ADMINHASH_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADMINHASH_DELETE:
            return { ...state, loading: true };
        case ADMINHASH_DELETE_SUCCESS:
            return { ...state, AdminhashDelete: action.payload, loading: false, error: null };
        case ADMINHASH_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
            case ADMINHASH_SHOW:
                return { ...state, listloading: true };
            case ADMINHASH_SHOW_SUCCESS:
                return { ...state, Adminhashdata: action.payload, listloading: false, error: null };
            case ADMINHASH_SHOW_FAILED:
                return { ...state, error: action.payload, loading: false };



                case  TOPICHASH_LIST:
                    return { ...state, listloading: true };
                case  TOPICHASH_LIST_SUCCESS:
                    return { ...state, Topichash: action.payload, listloading: false, error: null };
                case  TOPICHASH_LIST_FAILED:
                    return { ...state, error: action.payload, loading: false };
                case  TOPICHASH_ADD:
                    return { ...state, loading: true };
                case  TOPICHASH_ADD_SUCCESS:
                    return { ...state, TopichashAdd: action.payload, loading: false, error: null };
                case  TOPICHASH_ADD_FAILED:
                    return { ...state, error: action.payload, loading: false };
                case  TOPICHASH_UPDATE:
                    return { ...state, loading: true };
                case  TOPICHASH_UPDATE_SUCCESS:
                    return { ...state,TopichashUpdate: action.payload, loading: false, error: null };
                case  TOPICHASH_UPDATE_FAILED:
                    return { ...state, error: action.payload, loading: false };
                case  TOPICHASH_DELETE:
                    return { ...state, loading: true };
                case TOPICHASH_DELETE_SUCCESS:
                    return { ...state, TopichashDelete: action.payload, loading: false, error: null };
                case  TOPICHASH_DELETE_FAILED:
                    return { ...state, error: action.payload, loading: false };
                    case  TOPICHASH_SHOW:
                        return { ...state, listloading: true };
                    case  TOPICHASH_SHOW_SUCCESS:
                        return { ...state, Topichashdata: action.payload, listloading: false, error: null };
                    case  TOPICHASH_SHOW_FAILED:
                        return { ...state, error: action.payload, loading: false };


                        case TOPICHASH_LOCK:
                            return { ...state, loading: true };
                        case TOPICHASH_LOCK_SUCCESS:
                            return { ...state, TopichashLock: action.payload, loading: false, error: null };
                        case TOPICHASH_LOCK_FAILED:
                            return { ...state, error: action.payload, loading: false };

                            case TOPICHASH_UNLOCK:
                                return { ...state, loading: true };
                            case TOPICHASH_UNLOCK_SUCCESS:
                                return { ...state, TopichashUnlock: action.payload, loading: false, error: null };
                            case TOPICHASH_UNLOCK_FAILED:
                                return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Adminhash;
