/* SCORE */
export const SCORE_LIST = 'SCORE_LIST';
export const SCORE_LIST_SUCCESS = 'SCORE_LIST_SUCCESS';
export const SCORE_LIST_FAILED = 'SCORE_LIST_FAILED';


export const SCORE_PEDATA = 'SCORE_PEDATA';
export const SCORE_PEDATA_SUCCESS = 'SCORE_PEDATA_SUCCESS';
export const SCORE_PEDATA_FAILED = 'SCORE_PEDATA_FAILED';

export const SCORE_PEHISTORY = 'SCORE_PEHISTORY';
export const SCORE_PEHISTORY_SUCCESS = 'SCORE_PEHISTORY_SUCCESS';
export const SCORE_PEHISTORY_FAILED = 'SCORE_PEHISTORY_FAILED';

export const SCORE_ADD = 'SCORE_ADD';
export const SCORE_ADD_SUCCESS = 'SCORE_ADD_SUCCESS';
export const SCORE_ADD_FAILED = 'SCOREADD_ADD_FAILED';

export const SCORE_UPDATE = 'SCORE_UPDATE';
export const SCORE_UPDATE_SUCCESS = 'SCORE_UPDATE_SUCCESS';
export const SCORE_UPDATE_FAILED = 'SCORE_UPDATE_FAILED';

export const SCORE_DELETE = 'SCORE_DELETE';
export const SCORE_DELETE_SUCCESS = 'SCORE_DELETE_SUCCESS';
export const SCORE_DELETE_FAILED = 'SCOREADD_DELETE_FAILED';
