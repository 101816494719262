/* TEAM */
export const ONBOARD_LIST = 'ONBOARD_LIST';
export const ONBOARD_LIST_SUCCESS = 'ONBOARD_LIST_SUCCESS';
export const ONBOARD_LIST_FAILED = 'ONBOARD_LIST_FAILED';

export const ONBOARD_ADD = 'ONBOARD_ADD';
export const ONBOARD_ADD_SUCCESS = 'ONBOARD_ADD_SUCCESS';
export const ONBOARD_ADD_FAILED = 'TEAMADD_ADD_FAILED';

export const ONBOARD_UPDATE = 'ONBOARD_UPDATE';
export const ONBOARD_UPDATE_SUCCESS = 'ONBOARD_UPDATE_SUCCESS';
export const ONBOARD_UPDATE_FAILED = 'ONBOARD_UPDATE_FAILED';

export const ONBOARD_DELETE = 'ONBOARD_DELETE';
export const ONBOARD_DELETE_SUCCESS = 'ONBOARD_DELETE_SUCCESS';
export const ONBOARD_DELETE_FAILED = 'TEAMADD_DELETE_FAILED';


export const ONBOARD_LISTVIEW ='ONBOARD_LISTVIEW';
export const ONBOARD_LISTVIEW_SUCCESS='ONBOARD_LISTVIEW_SUCCESS';
export const ONBOARD_LISTVIEW_FAILED='ONBOARD_LISTVIEW_FAILED';



export const ONBOARD_INVITE='ONBOARD_INVITE';
export const ONBOARD_INVITE_SUCCESS='ONBOARD_INVITE_SUCCESS';
export const ONBOARD_INVITE_FAILED='ONBOARD_INVITE_FAILED';