/* PROJECT */
export const PROJECT_LIST = 'PROJECT_LIST';
export const PROJECT_LIST_SUCCESS = 'PROJECT_LIST_SUCCESS';
export const PROJECT_LIST_FAILED = 'PROJECT_LIST_FAILED';

export const PROJECT_ADD = 'PROJECT_ADD';
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS';
export const PROJECT_ADD_FAILED = 'PROJECTADD_ADD_FAILED';

export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILED = 'PROJECT_UPDATE_FAILED';

export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const PROJECT_DELETE_FAILED = 'PROJECTADD_DELETE_FAILED';
