// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { ONBOARD_LIST, ONBOARD_ADD, ONBOARD_UPDATE, ONBOARD_DELETE,  ONBOARD_LISTVIEW, ONBOARD_LISTVIEW_SUCCESS,ONBOARD_LISTVIEW_FAILED,    ONBOARD_INVITE,
    ONBOARD_INVITE_SUCCESS,
    ONBOARD_INVITE_FAILED} from './constants';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    getOnboardListSuccess,
    getOnboardListFailed,
    getOnboardAddSuccess,
    getOnboardAddFailed,
    getOnboardUpdateSuccess,
    getOnboardUpdateFailed,
    getOnboardDeleteSuccess,
    getOnboardDeleteFailed,
    getOnboardListView,
    getOnboardListViewSuccess,
    getOnboardListViewFailed,
    getOnboardInvite,
    getOnboardInviteSuccess,
    getOnboardInviteFailed

} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const OnboardAddedSucsess = () => toast.success('Onboard Added Successfully', { transition: Zoom });
const OnboardDeletedSuccess = () => toast.success('Onboard Deleted Successfully', { transition: Zoom });
const OnboardUpdated = () => toast.info('Onboard Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please Fill All Fields', { transition: Zoom });
const OnboardInvite = () => toast.info('Invitation sent Successfully', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getOnboardList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.OnboardList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getOnboardListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getOnboardListFailed(message));
    }
}

// Onboard Add

function* OnboardAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.OnboardAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        OnboardAddedSucsess();
     //   console.log(response.data);
        yield put(getOnboardAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getOnboardAddFailed(message));
    }
}

// Onboard Update

function* OnboardUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.OnboardUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        OnboardUpdated();
        yield put(getOnboardUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getOnboardUpdateFailed(message));
    }
}

// Onboard Delete

function* OnboardDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.OnboardDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        OnboardDeletedSuccess();
        yield put(getOnboardDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getOnboardDeleteFailed(message));
    }
}

//
function* getOnboardViewList({payload:data}) {
    const user = getLoggedInUser();
   
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.OnboardView+'/'+data.param_id,
        
    };
    try {
        const response = yield call(ApiCall, options);

        yield put(getOnboardListViewSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getOnboardListViewFailed(message));
    }
}
function* getOnboardInviteMail({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.OnboardInvite+'/'+data,
        
    };
    try {
        const response = yield call(ApiCall, options);
        OnboardInvite();
        yield put(getOnboardInviteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getOnboardInviteFailed(message));
    }
}


export function* watchgetOnboardList(): any {
    yield takeEvery(ONBOARD_LIST, getOnboardList);
}
export function* watchOnboardAdd(): any {
    yield takeEvery(ONBOARD_ADD, OnboardAdd);
}
export function* watchOnboardUpdate(): any {
    yield takeEvery(ONBOARD_UPDATE, OnboardUpdate);
}
export function* watchOnboardDelete(): any {
    yield takeEvery(ONBOARD_DELETE, OnboardDelete);
}
export function* watchOnboardViewList(): any {
    yield takeEvery(ONBOARD_LISTVIEW, getOnboardViewList);
}

export function* watchOnboardInviteMail(): any {
    yield takeEvery(ONBOARD_INVITE, getOnboardInviteMail);
}

function* authSaga(): any {
    yield all([fork(watchgetOnboardList), fork(watchOnboardAdd), fork(watchOnboardUpdate), fork(watchOnboardDelete),fork(watchOnboardViewList),fork(watchOnboardInviteMail)]);
}

export default authSaga;
