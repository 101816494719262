// @flow
import {
    REMAINDER_LIST,
    REMAINDER_LIST_SUCCESS,
    REMAINDER_LIST_FAILED,
    REMAINDER_ADD,
    REMAINDER_ADD_SUCCESS,
    REMAINDER_ADD_FAILED,
    REMAINDER_UPDATE,
    REMAINDER_UPDATE_SUCCESS,
    REMAINDER_UPDATE_FAILED,
    REMAINDER_DELETE,
    REMAINDER_DELETE_SUCCESS,
    REMAINDER_DELETE_FAILED
} from './constants';

type RemainderAction = { type: string, payload: {} | string };

export const getRemainderList = ():  RemainderAction => ({
    type: REMAINDER_LIST,
    payload: {},
});

export const getRemainderListSuccess = (remainder: string):  RemainderAction => ({
    type: REMAINDER_LIST_SUCCESS,
    payload: remainder,
});

export const getRemainderListFailed = (error: string):  RemainderAction => ({
    type: REMAINDER_LIST_FAILED,
    payload: error,
});

export const getRemainderAdd = (data:{}):  RemainderAction => ({
    type: REMAINDER_ADD,
    payload: data,
});

export const getRemainderAddSuccess = (remainderAdd: string):  RemainderAction => ({
    type: REMAINDER_ADD_SUCCESS,
    payload: remainderAdd,
});

export const getRemainderAddFailed = (error: string):  RemainderAction => ({
    type: REMAINDER_ADD_FAILED,
    payload: error,
});

export const getRemainderUpdate = (data:{},id):  RemainderAction => ({
    type: REMAINDER_UPDATE,
    payload: {
        data,
        id
    },});

export const getRemainderUpdateSuccess = (remainderUpdate: string):  RemainderAction => ({
    type: REMAINDER_UPDATE_SUCCESS,
    payload: remainderUpdate,
});

export const getRemainderUpdateFailed = (error: string):  RemainderAction => ({
    type: REMAINDER_UPDATE_FAILED,
    payload: error,
});

export const getRemainderDelete = (id):  RemainderAction => ({
    type: REMAINDER_DELETE,
    payload: id,
});

export const getRemainderDeleteSuccess = (remainderDelete: string):  RemainderAction => ({
    type: REMAINDER_DELETE_SUCCESS,
    payload: remainderDelete,
});

export const getRemainderDeleteFailed = (error: string):  RemainderAction => ({
    type: REMAINDER_DELETE_FAILED,
    payload: error,
});
