// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { REQUEST_TEAM_LIST, REQUEST_TEAM_APPROVE, REQUEST_TEAM_REJECT, REQUEST_TEAM_DELETE } from './constants';

import {
    getRequestTeamListSuccess,
    getRequestTeamListFailed,
    getRequestTeamApproveSuccess,
    getRequestTeamApproveFailed,
    getRequestTeamRejectSuccess,
    getRequestTeamRejectFailed,
    getRequestTeamDeleteSuccess,
    getRequestTeamDeleteFailed,
} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const requestApprovedSuccess = () => toast.info('Request Approved Successfully', { transition: Zoom });
const requestRejectedSuccess = () => toast.info('Request Rejected Successfully', { transition: Zoom });
const requestDeletedSuccess = () => toast.success('Request Deleted Successfully', { transition: Zoom });

const emptyAllFields = () => toast.warning('Please Fill All Fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* RequestTeamList() {
    const user = getLoggedInUser();
  
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
           
        },
     
        method: 'GET',
        url: endpoints.requestListUser+ '/'+ user.id,
       
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getRequestTeamListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRequestTeamListFailed(message));
    }
}

// Request Approve

function* RequestTeamApprove({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.requestApproveUser + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        requestApprovedSuccess();
        yield put(getRequestTeamApproveSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRequestTeamApproveFailed(message));
    }
}

// Request Reject

function* RequestTeamReject({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.requestRejectUser + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        requestRejectedSuccess();
        yield put(getRequestTeamRejectSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRequestTeamRejectFailed(message));
    }
}


// Request Delete

function* RequestTeamDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.requestDeleteUser + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        yield put(getRequestTeamDeleteSuccess(response.data));
        requestDeletedSuccess();
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRequestTeamDeleteFailed(message));
    }
}

export function* watchRequestTeamList(): any {
    yield takeEvery(REQUEST_TEAM_LIST, RequestTeamList);
}

export function* watchRequestTeamApprove(): any {
    yield takeEvery(REQUEST_TEAM_APPROVE, RequestTeamApprove);
}
export function* watchRequestTeamReject(): any {
    yield takeEvery(REQUEST_TEAM_REJECT, RequestTeamReject);
}
export function* watchRequestTeamDelete(): any {
    yield takeEvery(REQUEST_TEAM_DELETE, RequestTeamDelete);
}

function* authSaga(): any {
    yield all([
        fork(watchRequestTeamList),
        fork(watchRequestTeamApprove),
        fork(watchRequestTeamReject),
        fork(watchRequestTeamDelete),
    ]);
}

export default authSaga;
