// @flow
import {
    CLIENT_REVENUE_LIST,
    CLIENT_REVENUE_LIST_SUCCESS,
    CLIENT_REVENUE_LIST_FAILED,
    CLIENT_REVENUE_ADD,
    CLIENT_REVENUE_ADD_SUCCESS,
    CLIENT_REVENUE_ADD_FAILED,
    CLIENT_REVENUE_UPDATE,
    CLIENT_REVENUE_UPDATE_SUCCESS,
    CLIENT_REVENUE_UPDATE_FAILED,
    CLIENT_REVENUE_DELETE,
    CLIENT_REVENUE_DELETE_SUCCESS,
    CLIENT_REVENUE_DELETE_FAILED,
    COUNTRY_LIST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type ClientRevenueAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const ClientList = (state: State = INIT_STATE, action: ClientRevenueAction) => {
    switch (action.type) {
        case CLIENT_REVENUE_LIST:
            return { ...state, listloading: true };
        case CLIENT_REVENUE_LIST_SUCCESS:
            return { ...state, clientList: action.payload, listloading: false, error: null };
        case CLIENT_REVENUE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLIENT_REVENUE_ADD:
            return { ...state, loading: true };
        case CLIENT_REVENUE_ADD_SUCCESS:
            return { ...state, clientRevenueAdd: action.payload, loading: false, error: null };
        case CLIENT_REVENUE_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLIENT_REVENUE_UPDATE:
            return { ...state, loading: true };
        case CLIENT_REVENUE_UPDATE_SUCCESS:
            return { ...state, clientRevenueUpdate: action.payload, loading: false, error: null };
        case CLIENT_REVENUE_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLIENT_REVENUE_DELETE:
            return { ...state, loading: true };
        case CLIENT_REVENUE_DELETE_SUCCESS:            
            return { ...state, clientRevenueDelete: action.payload, loading: false, error: null };
        case     CLIENT_REVENUE_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
            case COUNTRY_LIST:
                return { ...state, listloading: true };
            case COUNTRY_LIST_SUCCESS:
                return { ...state, countryList: action.payload, listloading: false, error: null };
            case COUNTRY_LIST_FAILED:
                return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default ClientList;
