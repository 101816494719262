/* REQUEST */
export const REQUEST_TEAM_LIST = 'REQUEST_TEAM_LIST';
export const REQUEST_TEAM_LIST_SUCCESS = 'REQUEST_TEAM_LIST_SUCCESS';
export const REQUEST_TEAM_LIST_FAILED = 'REQUEST_TEAM_LIST_FAILED';



export const REQUEST_TEAM_APPROVE = 'REQUEST_TEAM_APPROVE';
export const REQUEST_TEAM_APPROVE_SUCCESS = 'REQUEST_TEAM_APPROVE_SUCCESS';
export const REQUEST_TEAM_APPROVE_FAILED = 'REQUEST_TEAM_APPROVE_FAILED';


export const REQUEST_TEAM_REJECT = 'REQUEST_TEAM_REJECT';
export const REQUEST_TEAM_REJECT_SUCCESS = 'REQUEST_TEAM_REJECT_SUCCESS';
export const REQUEST_TEAM_REJECT_FAILED = 'REQUEST_TEAM_REJECT_FAILED';


export const REQUEST_TEAM_DELETE = 'REQUEST_TEAM_DELETE';
export const REQUEST_TEAM_DELETE_SUCCESS = 'REQUEST_TEAM_DELETE_SUCCESS';
export const REQUEST_TEAM_DELETE_FAILED = 'REQUEST_TEAM_DELETE_FAILED';
