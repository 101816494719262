// @flow
import {
    CLIENT_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_LIST_FAILED,
    CLIENT_ADD,
    CLIENT_ADD_SUCCESS,
    CLIENT_ADD_FAILED,
    CLIENT_UPDATE,
    CLIENT_UPDATE_SUCCESS,
    CLIENT_UPDATE_FAILED,
    CLIENT_DELETE,
    CLIENT_DELETE_SUCCESS,
    CLIENT_DELETE_FAILED
} from './constants';

type ClientAction = { type: string, payload: {} | string };

export const getClientList = (): ClientAction => ({
    type: CLIENT_LIST,
    payload: {},
});

export const getClientListSuccess = (client: string): ClientAction => ({
    type: CLIENT_LIST_SUCCESS,
    payload: client,
});

export const getClientListFailed = (error: string): ClientAction => ({
    type: CLIENT_LIST_FAILED,
    payload: error,
});

export const getClientAdd = (data:{}): ClientAction => ({
    type: CLIENT_ADD,
    payload: data,
});

export const getClientAddSuccess = (clientAdd: string): ClientAction => ({
    type: CLIENT_ADD_SUCCESS,
    payload: clientAdd,
});

export const getClientAddFailed = (error: string): ClientAction => ({
    type: CLIENT_ADD_FAILED,
    payload: error,
});

export const getClientUpdate = (data:{}): ClientAction => ({
    type: CLIENT_UPDATE,
    payload: data,
});

export const getClientUpdateSuccess = (clientUpdate: string): ClientAction => ({
    type: CLIENT_UPDATE_SUCCESS,
    payload: clientUpdate,
});

export const getClientUpdateFailed = (error: string): ClientAction => ({
    type: CLIENT_UPDATE_FAILED,
    payload: error,
});

export const getClientDelete = (id): ClientAction => ({
    type: CLIENT_DELETE,
    payload: id,
});

export const getClientDeleteSuccess = (clientDelete: string): ClientAction => ({
    type: CLIENT_DELETE_SUCCESS,
    payload: clientDelete,
});

export const getClientDeleteFailed = (error: string): ClientAction => ({
    type: CLIENT_DELETE_FAILED,
    payload: error,
});
