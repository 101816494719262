/* PAYROLL */
export const PAY_DETAILS_LIST = 'PAY_DETAILS_LIST';
export const PAYROLL_DETAILS_LIST_SUCCESS = 'PAYROLL_LIST_SUCCESS';
export const PAYROLL_DETAILS_LIST_FAILED = 'PAYROLL_LIST_FAILED';

export const PAY_DETAILS_ADD = 'PAY_DETAILS_ADD';
export const PAY_DETAILS_ADD_SUCCESS = 'PAY_DETAILS_ADD_SUCCESS';
export const PAY_DETAILS_ADD_FAILED = 'PAY_DETAILS_ADD_FAILED';

// export const PAYROLL_DETAILS_UPDATE = 'PAYROLL_UPDATE';
// export const PAYROLL_DETAILS_UPDATE_SUCCESS = 'PAYROLL_UPDATE_SUCCESS';
// export const PAYROLL_DETAILS_UPDATE_FAILED = 'PAYROLL_UPDATE_FAILED';

export const PAY_DETAILS_DELETE = 'PAY_DETAILS_DELETE';
export const PAY_DETAILS_DELETE_SUCCESS = 'PAY_DETAILS_DELETE_SUCCESS';
export const PAY_DETAILS_DELETE_FAILED = 'PAY_DETAILS_DELETE_FAILED';

export const PAY_UPDATE  ='PAY_UPDATE' ;
export const PAY_UPDATE_SUCCESS  ='PAY_UPDATE_SUCCESS' ;
export const PAY_UPDATE_FAILED   = 'PAY_UPDATE_FAILED';

export const SALARY_DETAILS_FETCH='SALARY_DETAILS_FETCH';
export const SALARY_DETAILS_FETCH_SUCCESS= 'SALARY_DETAILS_FETCH_SUCCESS';
export const SALARY_DETAILS_FETCH_FAILED='SALARY_DETAILS_FETCH_FAILED';
