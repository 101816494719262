/* ANNOUNCEMENT */
export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const ANNOUNCEMENT_LIST_SUCCESS = 'ANNOUNCEMENT_LIST_SUCCESS';
export const ANNOUNCEMENT_LIST_FAILED = 'ANNOUNCEMENT_LIST_FAILED';

export const ANNOUNCEMENT_ADD = 'ANNOUNCEMENT_ADD';
export const ANNOUNCEMENT_ADD_SUCCESS = 'ANNOUNCEMENT_ADD_SUCCESS';
export const ANNOUNCEMENT_ADD_FAILED = 'ANNOUNCEMENTADD_ADD_FAILED';

export const ANNOUNCEMENT_UPDATE = 'ANNOUNCEMENT_UPDATE';
export const ANNOUNCEMENT_UPDATE_SUCCESS = 'ANNOUNCEMENT_UPDATE_SUCCESS';
export const ANNOUNCEMENT_UPDATE_FAILED = 'ANNOUNCEMENT_UPDATE_FAILED';

export const ANNOUNCEMENT_DELETE = 'ANNOUNCEMENT_DELETE';
export const ANNOUNCEMENT_DELETE_SUCCESS = 'ANNOUNCEMENT_DELETE_SUCCESS';
export const ANNOUNCEMENT_DELETE_FAILED = 'ANNOUNCEMENTADD_DELETE_FAILED';
