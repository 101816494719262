// @flow
import {
    CLIENT_REVENUE_LIST,
    CLIENT_REVENUE_LIST_SUCCESS,
    CLIENT_REVENUE_LIST_FAILED,
    CLIENT_REVENUE_ADD,
    CLIENT_REVENUE_ADD_SUCCESS,
    CLIENT_REVENUE_ADD_FAILED,
    CLIENT_REVENUE_UPDATE,
    CLIENT_REVENUE_UPDATE_SUCCESS,
    CLIENT_REVENUE_UPDATE_FAILED,
    CLIENT_REVENUE_DELETE,
    CLIENT_REVENUE_DELETE_SUCCESS,
    CLIENT_REVENUE_DELETE_FAILED,
    COUNTRY_LIST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAILED,
} from './constants';

type ClientRevenueAction = { type: string, payload: {} | string };

export const getClientRevenueList = (): ClientRevenueAction => ({
    type: CLIENT_REVENUE_LIST,
    payload: {},
});

export const getClientRevenueListSuccess = (clientList: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_LIST_SUCCESS,
    payload: clientList,
});

export const getClientRevenueListFailed = (error: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_LIST_FAILED,
    payload: error,
});

export const getClientRevenueAdd = (data:{}): ClientRevenueAction => ({
    type: CLIENT_REVENUE_ADD,
    payload: data,
});

export const getClientRevenueAddSuccess = (clientRevenueAdd: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_ADD_SUCCESS,
    payload: clientRevenueAdd,
});

export const getClientRevenueAddFailed = (error: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_ADD_FAILED,
    payload: error,
});

export const getClientRevenueUpdate = (data:{}): ClientRevenueAction => ({
    type: CLIENT_REVENUE_UPDATE,
    payload: data,
});

export const getClientRevenueUpdateSuccess = (clientRevenueUpdate: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_UPDATE_SUCCESS,
    payload: clientRevenueUpdate,
});

export const getClientRevenueUpdateFailed = (error: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_UPDATE_FAILED,
    payload: error,
});

export const getClientRevenueDelete = (id): ClientRevenueAction => ({
    type: CLIENT_REVENUE_DELETE,
    payload: id,
});

export const getClientRevenueDeleteSuccess = (clientRevenueDelete: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_DELETE_SUCCESS,
    payload: clientRevenueDelete,
});

export const getClientRevenueDeleteFailed = (error: string): ClientRevenueAction => ({
    type: CLIENT_REVENUE_DELETE_FAILED,
    payload: error,
});

export const getCountryList = (): ClientRevenueAction => ({
    type: COUNTRY_LIST,
    payload: {},
});

export const getCountryListSuccess = (countryList: string): ClientRevenueAction => ({
    type: COUNTRY_LIST_SUCCESS,
    payload: countryList,
});

export const getCountryListFailed = (error: string): ClientRevenueAction => ({
    type: COUNTRY_LIST_FAILED,
    payload: error,
});
