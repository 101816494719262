// @flow
import {
    REQUEST_TEAM_LIST,
    REQUEST_TEAM_LIST_SUCCESS,
    REQUEST_TEAM_LIST_FAILED,    
    REQUEST_TEAM_APPROVE,
    REQUEST_TEAM_APPROVE_SUCCESS,
    REQUEST_TEAM_APPROVE_FAILED,
    REQUEST_TEAM_REJECT,
    REQUEST_TEAM_REJECT_SUCCESS,
    REQUEST_TEAM_REJECT_FAILED,
    REQUEST_TEAM_DELETE,
    REQUEST_TEAM_DELETE_SUCCESS,
    REQUEST_TEAM_DELETE_FAILED
} from './constants';

type RequestTeamAction = { type: string, payload: {} | string };

export const getTeamRequestList = (): RequestTeamAction => ({
    type: REQUEST_TEAM_LIST,
    payload: {},
});

export const getRequestTeamListSuccess = (request: string): RequestTeamAction => ({
    type: REQUEST_TEAM_LIST_SUCCESS,
    payload: request,
});

export const getRequestTeamListFailed = (error: string): RequestTeamAction => ({
    type: REQUEST_TEAM_LIST_FAILED,
    payload: error,
});


export const getRequestTeamApprove = (id): RequestTeamAction => ({
    type: REQUEST_TEAM_APPROVE,
    payload: id,
});

export const getRequestTeamApproveSuccess = (requestApprove: string): RequestTeamAction => ({
    type: REQUEST_TEAM_APPROVE_SUCCESS,
    payload: requestApprove,
});

export const getRequestTeamApproveFailed = (error: string): RequestTeamAction => ({
    type: REQUEST_TEAM_APPROVE_FAILED,
    payload: error,
});



export const getRequestTeamReject = (id): RequestTeamAction => ({
    type: REQUEST_TEAM_REJECT,
    payload: id,
});

export const getRequestTeamRejectSuccess = (requestReject: string): RequestTeamAction => ({
    type: REQUEST_TEAM_REJECT_SUCCESS,
    payload: requestReject,
});

export const getRequestTeamRejectFailed = (error: string): RequestTeamAction => ({
    type: REQUEST_TEAM_REJECT_FAILED,
    payload: error,
});


export const getRequestTeamDelete = (id): RequestTeamAction => ({
    type: REQUEST_TEAM_DELETE,
    payload: id,
});

export const getRequestTeamDeleteSuccess = (requestDelete: string): RequestTeamAction => ({
    type: REQUEST_TEAM_DELETE_SUCCESS,
    payload: requestDelete,
});

export const getRequestTeamDeleteFailed = (error: string): RequestTeamAction => ({
    type: REQUEST_TEAM_DELETE_FAILED,
    payload: error,
});
