/* ROLE */
export const ROLE_LIST = 'ROLE_LIST';
export const ROLE_LIST_SUCCESS = 'ROLE_LIST_SUCCESS';
export const ROLE_LIST_FAILED = 'ROLE_LIST_FAILED';

export const ROLE_ADD = 'ROLE_ADD';
export const ROLE_ADD_SUCCESS = 'ROLE_ADD_SUCCESS';
export const ROLE_ADD_FAILED = 'ROLEADD_ADD_FAILED';

export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_FAILED = 'ROLE_UPDATE_FAILED';

export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_FAILED = 'ROLEADD_DELETE_FAILED';
