/* INTERVIEW */
export const INTERVIEW_LIST = 'INTERVIEW_LIST';
export const INTERVIEW_LIST_SUCCESS = 'INTERVIEW_LIST_SUCCESS';
export const INTERVIEW_LIST_FAILED = 'INTERVIEW_LIST_FAILED';

export const INTERVIEW_ADD = 'INTERVIEW_ADD';
export const INTERVIEW_ADD_SUCCESS = 'INTERVIEW_ADD_SUCCESS';
export const INTERVIEW_ADD_FAILED = 'INTERVIEWADD_ADD_FAILED';

export const INTERVIEW_UPDATE = 'INTERVIEW_UPDATE';
export const INTERVIEW_UPDATE_SUCCESS = 'INTERVIEW_UPDATE_SUCCESS';
export const INTERVIEW_UPDATE_FAILED = 'INTERVIEW_UPDATE_FAILED';

export const INTERVIEW_DELETE = 'INTERVIEW_DELETE';
export const INTERVIEW_DELETE_SUCCESS = 'INTERVIEW_DELETE_SUCCESS';
export const INTERVIEW_DELETE_FAILED = 'INTERVIEWADD_DELETE_FAILED';

export const COMMENT_ADD = 'COMMENT_ADD';
export const COMMENT_ADD_SUCCESS = 'COMMENT_ADD_SUCCESS';
export const COMMENT_ADD_FAILED = 'COMMENT_ADD_FAILED';
