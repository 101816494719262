/* TASKER */
export const TASKER_LIST = 'TASKER_LIST';
export const TASKER_LIST_SUCCESS = 'TASKER_LIST_SUCCESS';
export const TASKER_LIST_FAILED = 'TASKER_LIST_FAILED';

export const TASKER_ADD = 'TASKER_ADD';
export const TASKER_ADD_SUCCESS = 'TASKER_ADD_SUCCESS';
export const TASKER_ADD_FAILED = 'TASKERADD_ADD_FAILED';

export const TASKER_UPDATE = 'TASKER_UPDATE';
export const TASKER_UPDATE_SUCCESS = 'TASKER_UPDATE_SUCCESS';
export const TASKER_UPDATE_FAILED = 'TASKER_UPDATE_FAILED';

export const TASKER_DELETE = 'TASKER_DELETE';
export const TASKER_DELETE_SUCCESS = 'TASKER_DELETE_SUCCESS';
export const TASKER_DELETE_FAILED = 'TASKERADD_DELETE_FAILED';

export const TASKER_COMMENT = 'TASKER_COMMENT';
export const TASKER_COMMENT_SUCCESS = 'TASKER_COMMENT_SUCCESS';
export const TASKER_COMMENT_FAILED = 'TASKERADD_COMMENT_FAILED';
