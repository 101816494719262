// @flow
import {
    MONTHLYACTIVITYDATA_LIST,
    MONTHLYACTIVITYDATA_LIST_SUCCESS,
    MONTHLYACTIVITYDATA_LIST_FAILED,

} from './constants';

type MonthlyactivitydataAction = { type: string, payload: {} | string };

export const getMonthlyactivitydataList = (data:{}): MonthlyactivitydataAction => ({
    type: MONTHLYACTIVITYDATA_LIST,
    payload: data,
});

export const getMonthlyactivitydataListSuccess = (monthlyactivitydata: string): MonthlyactivitydataAction => ({
    type: MONTHLYACTIVITYDATA_LIST_SUCCESS,
    payload: monthlyactivitydata,
});

export const getMonthlyactivitydataListFailed = (error: string): MonthlyactivitydataAction => ({
    type: MONTHLYACTIVITYDATA_LIST_FAILED,
    payload: error,
});
