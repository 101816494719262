// @flow
import {
    ADMIN_LIST,
    ADMIN_LIST_SUCCESS,
    ADMIN_LIST_FAILED,
    ADMIN_ADD,
    ADMIN_ADD_SUCCESS,
    ADMIN_ADD_FAILED,
    ADMIN_UPDATE,
    ADMIN_UPDATE_SUCCESS,
    ADMIN_UPDATE_FAILED,
    ADMIN_DELETE,
    ADMIN_DELETE_SUCCESS,
    ADMIN_DELETE_FAILED
} from './constants';

type AdminAction = { type: string, payload: {} | string };

export const getAdminList = (): AdminAction => ({
    type: ADMIN_LIST,
    payload: {},
});

export const getAdminListSuccess = (Admin: string): AdminAction => ({
    type: ADMIN_LIST_SUCCESS,
    payload: Admin,
});

export const getAdminListFailed = (error: string): AdminAction => ({
    type: ADMIN_LIST_FAILED,
    payload: error,
});

export const getAdminAdd = (data:{}): AdminAction => ({
    type: ADMIN_ADD,
    payload: data,
});

export const getAdminAddSuccess = (AdminAdd: string): AdminAction => ({
    type: ADMIN_ADD_SUCCESS,
    payload: AdminAdd,
});

export const getAdminAddFailed = (error: string): AdminAction => ({
    type: ADMIN_ADD_FAILED,
    payload: error,
});

export const getAdminUpdate = (data:{}): AdminAction => ({
    type: ADMIN_UPDATE,
    payload: data,
});

export const getAdminUpdateSuccess = (AdminUpdate: string): AdminAction => ({
    type: ADMIN_UPDATE_SUCCESS,
    payload: AdminUpdate,
});

export const getAdminUpdateFailed = (error: string): AdminAction => ({
    type: ADMIN_UPDATE_FAILED,
    payload: error,
});

export const getAdminDelete = (id): AdminAction => ({
    type: ADMIN_DELETE,
    payload: id,
});

export const getAdminDeleteSuccess = (AdminDelete: string): AdminAction => ({
    type: ADMIN_DELETE_SUCCESS,
    payload: AdminDelete,
});

export const getAdminDeleteFailed = (error: string): AdminAction => ({
    type: ADMIN_DELETE_FAILED,
    payload: error,
});
