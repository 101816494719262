/* REQUEST */
export const REQUEST_LIST = 'REQUEST_LIST';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_FAILED = 'REQUEST_LIST_FAILED';



export const REQUEST_APPROVE = 'REQUEST_APPROVE';
export const REQUEST_APPROVE_SUCCESS = 'REQUEST_APPROVE_SUCCESS';
export const REQUEST_APPROVE_FAILED = 'REQUEST_APPROVE_FAILED';


export const REQUEST_REJECT = 'REQUEST_REJECT';
export const REQUEST_REJECT_SUCCESS = 'REQUEST_REJECT_SUCCESS';
export const REQUEST_REJECT_FAILED = 'REQUEST_REJECT_FAILED';


export const REQUEST_DELETE = 'REQUEST_DELETE';
export const REQUEST_DELETE_SUCCESS = 'REQUEST_DELETE_SUCCESS';
export const REQUEST_DELETE_FAILED = 'REQUESTADD_DELETE_FAILED';
