// @flow
import {
  GRAPH_LIST,
  GRAPH_LIST_SUCCESS,
  GRAPH_LIST_FAILED,

} from "./constants";

type GraphAction = { type: string, payload: {} | string };

export const getGraphList = (data:{}): GraphAction => ({
  type:  GRAPH_LIST,
  payload: data,
});

export const getGraphListSuccess = (graph: string):GraphAction => ({
  type:  GRAPH_LIST_SUCCESS,
  payload: graph,
});

export const getGraphListFailed = (error: string): GraphAction => ({
  type: GRAPH_LIST_FAILED,
  payload: error,
});

