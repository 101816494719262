// @flow
import {
    USERHASH_LIST,
    USERHASH_LIST_SUCCESS,
    USERHASH_LIST_FAILED,
    USERHASH_SHOW,
    USERHASH_SHOW_SUCCESS,
    USERHASH_SHOW_FAILED,
    TOPICHASH_SHOW,
    TOPICHASH_SHOW_SUCCESS,
    TOPICHASH_SHOW_FAILED,

    DEPTEAM_LIST,
    DEPTEAM_LIST_SUCCESS,
    DEPTEAM_LIST_FAILED,

    USERHASH_ADD,
    USERHASH_ADD_SUCCESS,
    USERHASH_ADD_FAILED,


    USERTOPICHASH_UPDATE,
    USERTOPICHASH_UPDATE_SUCCESS,
    USERTOPICHASH_UPDATE_FAILED,

    USERSUBHASH_ADD,
    USERSUBHASH_ADD_SUCCESS,
    USERSUBHASH_ADD_FAILED,


    USERSUBTOPICHASH_UPDATE,
    USERSUBTOPICHASH_UPDATE_SUCCESS,
    USERSUBTOPICHASH_UPDATE_FAILED,




    
} from './constants';

type UserhashAction = { type: string, payload: {} | string };

export const getUserhashList = (data:{}): UserhashAction => ({
    type: USERHASH_LIST,
    payload: data,
});

export const getUserhashListSuccess = (Userhash: string): UserhashAction => ({
    type:USERHASH_LIST_SUCCESS,
    payload: Userhash,
});

export const getUserhashListFailed = (error: string): UserhashAction => ({
    type:USERHASH_LIST_FAILED,
    payload: error,
});


export const getUserhashShowList = (data:{}): UserhashAction => ({
    type:USERHASH_SHOW,
    payload: data,
});

export const getUserhashShowListSuccess = (Userhashdata: string): UserhashAction => ({
    type:USERHASH_SHOW_SUCCESS,
    payload: Userhashdata,
});

export const getUserhashShowListFailed = (error: string): UserhashAction => ({
    type:USERHASH_SHOW_FAILED,
    payload: error,
});



export const getUserTopichashShowList = (): UserhashAction => ({
    type:TOPICHASH_SHOW,
    payload: {},
});

export const getUserTopichashShowListSuccess = (Topichashdata: string): UserhashAction => ({
    type:TOPICHASH_SHOW_SUCCESS,
    payload: Topichashdata,
});

export const getUserTopichashShowListFailed = (error: string): UserhashAction => ({
    type:TOPICHASH_SHOW_FAILED,
    payload: error,
});


export const getUserDepTeamList = (): UserhashAction => ({
    type:DEPTEAM_LIST,
    payload: {},
});

export const getUserDepTeamListSuccess = (depteamdata: string): UserhashAction => ({
    type:DEPTEAM_LIST_SUCCESS,
    payload: depteamdata,
});

export const getUserDepTeamListFailed = (error: string): UserhashAction => ({
    type:DEPTEAM_LIST_FAILED,
    payload: error,
});


export const getUserhashTopicAdd = (data:{}): UserhashAction => ({
    type: USERHASH_ADD,
    payload: data,
});

export const getUserhashTopicAddSuccess = (UserhashAdd: string): UserhashAction => ({
    type: USERHASH_ADD_SUCCESS,
    payload: UserhashAdd,
});

export const getUserhashTopicAddFailed = (error: string):UserhashAction => ({
    type: USERHASH_ADD_FAILED,
    payload: error,
});

export const getUserTopichashUpdate = (data:{}): UserhashAction => ({
    type: USERTOPICHASH_UPDATE,
    payload: data,
});

export const getUserTopichashUpdateSuccess = (UserTopichashUpdate: string):UserhashAction => ({
    type: USERTOPICHASH_UPDATE_SUCCESS,
    payload: UserTopichashUpdate,
});

export const getUserTopichashUpdateFailed = (error: string): UserhashAction => ({
    type: USERTOPICHASH_UPDATE_FAILED,
    payload: error,
});



/////

export const getUserhashSubTopicAdd = (data:{}): UserhashAction => ({
    type: USERSUBHASH_ADD,
    payload: data,
});

export const getUserhashSubTopicAddSuccess = (UserSubhashAdd: string): UserhashAction => ({
    type: USERSUBHASH_ADD_SUCCESS,
    payload: UserSubhashAdd,
});

export const getUserhashSubTopicAddFailed = (error: string):UserhashAction => ({
    type: USERSUBHASH_ADD_FAILED,
    payload: error,
});

export const getUserSubTopichashUpdate = (data:{}): UserhashAction => ({
    type: USERSUBTOPICHASH_UPDATE,
    payload: data,
});

export const getUserSubTopichashUpdateSuccess = (UserSubTopichashUpdate: string):UserhashAction => ({
    type: USERSUBTOPICHASH_UPDATE_SUCCESS,
    payload: UserSubTopichashUpdate,
});

export const getUserSubTopichashUpdateFailed = (error: string): UserhashAction => ({
    type: USERSUBTOPICHASH_UPDATE_FAILED,
    payload: error,
});