// @flow
import {
    PAY_DETAILS_LIST,
    PAYROLL_DETAILS_LIST_SUCCESS,
    PAYROLL_DETAILS_LIST_FAILED,
   PAY_DETAILS_ADD,
    PAY_DETAILS_ADD_SUCCESS,
    PAY_DETAILS_ADD_FAILED,
    // PAYROLL_DETAILS_UPDATE,
    // PAYROLL_DETAILS_UPDATE_SUCCESS,
    // PAYROLL_DETAILS_UPDATE_FAILED,
    // PAYROLL_DETAILS_DELETE,
    // PAYROLL_DETAILS_DELETE_SUCCESS,
    // PAYROLL_DETAILS_DELETE_FAILED,
    // PAYROLL_DETAILS_EMPLOYEE,
    // PAYROLL_DETAILS_EMPLOYEE_LIST_SUCCESS,
    // PAYROLL_DETAILS_EMPLOYEE_LIST_FAILED,
    PAY_UPDATE,
    PAY_UPDATE_SUCCESS,
    PAY_UPDATE_FAILED,
    PAY_DETAILS_DELETE,
    PAY_DETAILS_DELETE_SUCCESS,
    PAY_DETAILS_DELETE_FAILED,
    SALARY_DETAILS_FETCH,
    SALARY_DETAILS_FETCH_SUCCESS,
    SALARY_DETAILS_FETCH_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type PayDetailsAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const paydetails = (state: State = INIT_STATE, action: PayDetailsAction) => {
    switch (action.type) {
        case PAY_DETAILS_LIST:
            return { ...state, listloading: true };
        case PAYROLL_DETAILS_LIST_SUCCESS:
            return { ...state, PayDetails: action.payload, listloading: false, error: null };
        case PAYROLL_DETAILS_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAY_DETAILS_ADD:
            return { ...state, loading: true };
        case PAY_DETAILS_ADD_SUCCESS:
            return { ...state, PayAdd: action.payload, loading: false, error: null };
        case PAY_DETAILS_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAY_UPDATE:
            return { ...state, loading: true };
        case PAY_UPDATE_SUCCESS:
            return { ...state, PayUpdate: action.payload, loading: false, error: null };
        case PAY_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAY_DETAILS_DELETE:
            return { ...state, loading: true };
        case PAY_DETAILS_DELETE_SUCCESS:
            return { ...state, PayDelete: action.payload, loading: false, error: null };
        case PAY_DETAILS_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };

        //     case PAYROLL_DETAILS_EMPLOYEE:
        //         return { ...state, listloading: false };
        //     case PAYROLL_DETAILS_EMPLOYEE_LIST_SUCCESS:
        //         return { ...state, EmpData: action.payload, listloading: false, error: null };
        //     case PAYROLL_DETAILS_EMPLOYEE_LIST_FAILED:
        //         return { ...state, error: action.payload, loading: false };

        case SALARY_DETAILS_FETCH:
            return { ...state, loading: true };
        case SALARY_DETAILS_FETCH_SUCCESS:
            return { ...state, salaryFetch: action.payload, loading: false, error: null };
        case SALARY_DETAILS_FETCH_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default paydetails;
