/* DESIGNATION */
export const DESIGNATION_LIST = 'DESIGNATION_LIST';
export const DESIGNATION_LIST_SUCCESS = 'DESIGNATION_LIST_SUCCESS';
export const DESIGNATION_LIST_FAILED = 'DESIGNATION_LIST_FAILED';

export const DESIGNATION_ADD = 'DESIGNATION_ADD';
export const DESIGNATION_ADD_SUCCESS = 'DESIGNATION_ADD_SUCCESS';
export const DESIGNATION_ADD_FAILED = 'DESIGNATIONADD_ADD_FAILED';

export const DESIGNATION_UPDATE = 'DESIGNATION_UPDATE';
export const DESIGNATION_UPDATE_SUCCESS = 'DESIGNATION_UPDATE_SUCCESS';
export const DESIGNATION_UPDATE_FAILED = 'DESIGNATION_UPDATE_FAILED';

export const DESIGNATION_DELETE = 'DESIGNATION_DELETE';
export const DESIGNATION_DELETE_SUCCESS = 'DESIGNATION_DELETE_SUCCESS';
export const DESIGNATION_DELETE_FAILED = 'DESIGNATIONADD_DELETE_FAILED';
