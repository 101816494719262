/* PAYROLL */
export const PAY_LIST = 'PAY_LIST';
export const PAYROLL_LIST_SUCCESS = 'PAYROLL_LIST_SUCCESS';
export const PAYROLL_LIST_FAILED = 'PAYROLL_LIST_FAILED';

export const PAYROLL_ADD = 'PAYROLL_ADD';
export const PAYROLL_ADD_SUCCESS = 'PAYROLL_ADD_SUCCESS';
export const PAYROLL_ADD_FAILED = 'PAYROLLADD_ADD_FAILED';

export const PAYROLL_UPDATE = 'PAYROLL_UPDATE';
export const PAYROLL_UPDATE_SUCCESS = 'PAYROLL_UPDATE_SUCCESS';
export const PAYROLL_UPDATE_FAILED = 'PAYROLL_UPDATE_FAILED';

export const PAYROLL_DELETE = 'PAYROLL_DELETE';
export const PAYROLL_DELETE_SUCCESS = 'PAYROLL_DELETE_SUCCESS';
export const PAYROLL_DELETE_FAILED = 'PAYROLLADD_DELETE_FAILED';



export const PAYROLL_EMPLOYEE='PAYROLL_EMPLOYEE';
export const PAYROLL_EMPLOYEE_LIST_SUCCESS= 'PAYROLL_LIST_SUCCESS';
export const PAYROLL_EMPLOYEE_LIST_FAILED='PAYROLL_LIST_FAILED';
