// @flow
import {
    REPORTMONTH_LIST,
    REPORTMONTH_LIST_SUCCESS,
    REPORTMONTH_LIST_FAILED,
    REPORT_LIST,
    REPORT_LIST_SUCCESS,
    REPORT_LIST_FAILED,

} from './constants';

type ReportMonthlyAction = { type: string, payload: {} | string };

export const getReportMonthList = (data:{}): ReportMonthlyAction => ({
    type: REPORTMONTH_LIST,
    payload: data,
});

export const getReportMonthListSuccess = (reportmonth: string): ReportMonthlyAction => ({
    type:REPORTMONTH_LIST_SUCCESS,
    payload: reportmonth,
});

export const getReportMonthListFailed = (error: string): ReportMonthlyAction => ({
    type: REPORTMONTH_LIST_FAILED,
    payload: error,
});

export const getReportList = (data:{}): ReportMonthlyAction => ({
    type:REPORT_LIST,
    payload: data,
});

export const getReportListSuccess = (reportmonth: string): ReportMonthlyAction => ({
    type:REPORT_LIST_SUCCESS,
    payload: reportmonth,
});

export const getReportListFailed = (error: string): ReportMonthlyAction => ({
    type: REPORT_LIST_FAILED,
    payload: error,
});
