// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { fetchJSON } from "../../helpers/api";
import { ApiCall } from "../../services/index";
import { endpoints } from "../../services/endpoints";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  HIKE_LIST,

} from "./constants";

import {
  getHikeListSuccess,
  getHikeListFailed,

} from "./actions";

import { getLoggedInUser } from "../../helpers/authUtils";

const mytaskAddedSucsess = () =>
  toast.success("Mytask Added Successfully", { transition: Zoom });
const mytaskCommentAddedSucsess = () =>
  toast.success("Comment Added Successfully", { transition: Zoom });
const mytaskDeletedSuccess = () =>
  toast.success("Mytask Deleted Successfully", { transition: Zoom });
const mytaskUpdated = () =>
  toast.info("Mytask Updated Successfully", { transition: Zoom });
const emptyAllFields = () =>
  toast.warning("Please Fill All Fields", { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* HikeList() {
  const user = getLoggedInUser();
  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    method: "GET",
    url: endpoints.getHike,
    // data: sendData
  };

  try {
    const response = yield call(ApiCall, options);

    yield put(getHikeListSuccess(response.data));
  } catch (error) {
    let message;
    switch (error.response.status) {
      case 500:
        message = "Internal Server Error";
        WarnFields(message);
        break;
      case 401:
        message = "Invalid credentials";
        WarnFields(message);
        break;
      case 400:
        message = error.response.data && error.response.data.error;
        WarnFields(message);
        break;
      default:
        message = error;
    }
    yield put(getHikeListFailed(message));
  }
}


export function* watchHikeListt(): any {
  yield takeEvery(HIKE_LIST, HikeList);
}


function* authSaga(): any {
  yield all([
    fork(watchHikeListt),
 

  ]);
}

export default authSaga;
