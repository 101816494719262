// @flow
import {
  HIKE_LIST,
  HIKE_LIST_SUCCESS,
  HIKE_LIST_FAILED,

} from "./constants";

type HikeAction = { type: string, payload: {} | string };

export const getHikeList = (): HikeAction => ({
  type:  HIKE_LIST,
  payload: {},
});

export const getHikeListSuccess = (hike: string):HikeAction => ({
  type:  HIKE_LIST_SUCCESS,
  payload: hike,
});

export const getHikeListFailed = (error: string): HikeAction => ({
  type:  HIKE_LIST_FAILED,
  payload: error,
});

