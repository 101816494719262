/* REPORTMONTH */
export const REPORTTEAMMONTH_LIST = 'REPORTTEAMMONTH_LIST';
export const REPORTTEAMMONTH_LIST_SUCCESS = 'REPORTTEAMMONTH_LIST_SUCCESS';
export const REPORTTEAMMONTH_LIST_FAILED = 'REPORTTEAMMONTH_LIST_FAILED';




/* REPORT */
export const REPORT_TEAM_LIST = 'REPORT_TEAM_LIST';
export const REPORT_TEAM_LIST_SUCCESS = 'REPORT_TEAM_LIST_SUCCESS';
export const REPORT_TEAM_LIST_FAILED = 'REPORT_TEAM_LIST_FAILED';

// team members
export const TEAM_MEMBERS_REPORT_LIST = 'TEAM_MEMBERS_REPORT_LIST';
export const TEAM_MEMBERS_REPORT_LIST_SUCCESS = 'TEAM_MEMBERS_REPORT_LIST_SUCCESS';
export const TEAM_MEMBERS_REPORT_LIST_FAILED = 'TEAM_MEMBERS_REPORT_LIST_FAILED';