// @flow
import {
    ADMINHASH_LIST,
    ADMINHASH_LIST_SUCCESS,
    ADMINHASH_LIST_FAILED,
    ADMINHASH_ADD,
    ADMINHASH_ADD_SUCCESS,
    ADMINHASH_ADD_FAILED,
    ADMINHASH_UPDATE,
    ADMINHASH_UPDATE_SUCCESS,
    ADMINHASH_UPDATE_FAILED,
    ADMINHASH_DELETE,
    ADMINHASH_DELETE_SUCCESS,
    ADMINHASH_DELETE_FAILED,
    ADMINHASH_SHOW,
    ADMINHASH_SHOW_SUCCESS,
    ADMINHASH_SHOW_FAILED,


    TOPICHASH_LIST,
    TOPICHASH_LIST_SUCCESS,
    TOPICHASH_LIST_FAILED,
    TOPICHASH_ADD,
    TOPICHASH_ADD_SUCCESS,
    TOPICHASH_ADD_FAILED,
    TOPICHASH_UPDATE,
    TOPICHASH_UPDATE_SUCCESS,
    TOPICHASH_UPDATE_FAILED,
    TOPICHASH_DELETE,
    TOPICHASH_DELETE_SUCCESS,
    TOPICHASH_DELETE_FAILED,
    TOPICHASH_SHOW,
    TOPICHASH_SHOW_SUCCESS,
    TOPICHASH_SHOW_FAILED,

    TOPICHASH_LOCK,
    TOPICHASH_LOCK_SUCCESS,
    TOPICHASH_LOCK_FAILED,
    TOPICHASH_UNLOCK,
    TOPICHASH_UNLOCK_SUCCESS,
    TOPICHASH_UNLOCK_FAILED,

} from './constants';

type AdminhashAction = { type: string, payload: {} | string };

export const getAdminhashList = (data:{}): AdminhashAction => ({
    type: ADMINHASH_LIST,
    payload: data,
});

export const getAdminhashListSuccess = (Adminhash: string): AdminhashAction => ({
    type:ADMINHASH_LIST_SUCCESS,
    payload: Adminhash,
});

export const getAdminhashListFailed = (error: string): AdminhashAction => ({
    type: ADMINHASH_LIST_FAILED,
    payload: error,
});

export const getAdminhashAdd = (data:{}): AdminhashAction => ({
    type: ADMINHASH_ADD,
    payload: data,
});

export const getAdminhashAddSuccess = (AdminhashAdd: string): AdminhashAction => ({
    type: ADMINHASH_ADD_SUCCESS,
    payload: AdminhashAdd,
});

export const getAdminhashAddFailed = (error: string):AdminhashAction => ({
    type: ADMINHASH_ADD_FAILED,
    payload: error,
});

export const getAdminhashUpdate = (data:{}): AdminhashAction => ({
    type: ADMINHASH_UPDATE,
    payload: data,
});

export const getAdminhashUpdateSuccess = (AdminhashUpdate: string):AdminhashAction => ({
    type: ADMINHASH_UPDATE_SUCCESS,
    payload: AdminhashUpdate,
});

export const getAdminhashUpdateFailed = (error: string): AdminhashAction => ({
    type: ADMINHASH_UPDATE_FAILED,
    payload: error,
});

export const getAdminhashDelete = (id): AdminhashAction => ({
    type: ADMINHASH_DELETE,
    payload: id,
});

export const getAdminhashDeleteSuccess = (AdminhashDelete: string): AdminhashAction => ({
    type: ADMINHASH_DELETE_SUCCESS,
    payload: AdminhashDelete,
});

export const getAdminhashDeleteFailed = (error: string): AdminhashAction => ({
    type:ADMINHASH_DELETE_FAILED,
    payload: error,
});
export const getAdminhashShowList = (data:{}): AdminhashAction => ({
    type:ADMINHASH_SHOW,
    payload: data,
});

export const getAdminhashShowListSuccess = (Adminhashdata: string): AdminhashAction => ({
    type:ADMINHASH_SHOW_SUCCESS,
    payload: Adminhashdata,
});

export const getAdminhashShowListFailed = (error: string): AdminhashAction => ({
    type:ADMINHASH_SHOW_FAILED,
    payload: error,
});


//////////////////////add Topic

export const getTopichashList = (): AdminhashAction => ({
    type: TOPICHASH_LIST,
    payload: {},
});

export const getTopichashListSuccess = (Topichash: string): AdminhashAction => ({
    type:TOPICHASH_LIST_SUCCESS,
    payload: Topichash,
});

export const getTopichashListFailed = (error: string): AdminhashAction => ({
    type: TOPICHASH_LIST_FAILED,
    payload: error,
});

export const getTopichashAdd = (data:{}): AdminhashAction => ({
    type: TOPICHASH_ADD,
    payload: data,
});

export const getTopichashAddSuccess = (TopichashAdd: string): AdminhashAction => ({
    type: TOPICHASH_ADD_SUCCESS,
    payload: TopichashAdd,
});

export const getTopichashAddFailed = (error: string):AdminhashAction => ({
    type: TOPICHASH_ADD_FAILED,
    payload: error,
});

export const getTopichashUpdate = (data:{}): AdminhashAction => ({
    type: TOPICHASH_UPDATE,
    payload: data,
});

export const getTopichashUpdateSuccess = (TopichashUpdate: string):AdminhashAction => ({
    type: TOPICHASH_UPDATE_SUCCESS,
    payload: TopichashUpdate,
});

export const getTopichashUpdateFailed = (error: string): AdminhashAction => ({
    type: TOPICHASH_UPDATE_FAILED,
    payload: error,
});

export const getTopichashDelete = (id): AdminhashAction => ({
    type: TOPICHASH_DELETE,
    payload: id,
});

export const getTopichashDeleteSuccess = (TopichashDelete: string): AdminhashAction => ({
    type: TOPICHASH_DELETE_SUCCESS,
    payload: TopichashDelete,
});

export const getTopichashDeleteFailed = (error: string): AdminhashAction => ({
    type:TOPICHASH_DELETE_FAILED,
    payload: error,
});
export const getTopichashShowList = (data:{}): AdminhashAction => ({
    type:TOPICHASH_SHOW,
    payload: data,
});

export const getTopichashShowListSuccess = (Topichashdata: string): AdminhashAction => ({
    type:TOPICHASH_SHOW_SUCCESS,
    payload: Topichashdata,
});

export const getTopichashShowListFailed = (error: string): AdminhashAction => ({
    type:TOPICHASH_SHOW_FAILED,
    payload: error,
});

//LOCK UNLOCK
export const getTopichashLock = (data:{}): AdminhashAction => ({
    type: TOPICHASH_LOCK,
    payload: data,
});

export const getTopichashLockSuccess = (TopichashLock: string): AdminhashAction => ({
    type: TOPICHASH_LOCK_SUCCESS,
    payload: TopichashLock,
});

export const getTopichashLockFailed = (error: string): AdminhashAction => ({
    type:TOPICHASH_LOCK_FAILED,
    payload: error,
});

export const getTopichashUnLock = (data:{}): AdminhashAction => ({
    type: TOPICHASH_UNLOCK,
    payload: data,
});

export const getTopichashUnLockSuccess = (TopichashUnlock: string): AdminhashAction => ({
    type: TOPICHASH_UNLOCK_SUCCESS,
    payload: TopichashUnlock,
});

export const getTopichashUnLockFailed = (error: string): AdminhashAction => ({
    type:TOPICHASH_UNLOCK_FAILED,
    payload: error,
});