// @flow
import {
    REPORTMONTH_LIST,
    REPORTMONTH_LIST_SUCCESS,
    REPORTMONTH_LIST_FAILED,
    REPORT_LIST,
    REPORT_LIST_SUCCESS,
    REPORT_LIST_FAILED,

} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type ReportMonthlyAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const ReportMonthly = (state: State = INIT_STATE, action: ReportMonthlyAction) => {
   
    switch (action.type) {
        case REPORTMONTH_LIST:
            return { ...state, listloading: true };
        case REPORTMONTH_LIST_SUCCESS:
            return { ...state, reportmonth: action.payload, listloading: false, error: null };
        case REPORTMONTH_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
      
            case REPORT_LIST:
            return { ...state, listloading: true };
            case REPORT_LIST_SUCCESS:
                return { ...state, reportdata: action.payload, listloading: false, error: null };
            case REPORT_LIST_FAILED:
                return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default ReportMonthly;
