// @flow
import {
    ROLE_LIST,
    ROLE_LIST_SUCCESS,
    ROLE_LIST_FAILED,
    ROLE_ADD,
    ROLE_ADD_SUCCESS,
    ROLE_ADD_FAILED,
    ROLE_UPDATE,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_FAILED,
    ROLE_DELETE,
    ROLE_DELETE_SUCCESS,
    ROLE_DELETE_FAILED
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type RoleAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Role = (state: State = INIT_STATE, action: RoleAction) => {
    switch (action.type) {
        case ROLE_LIST:
            return { ...state, listloading: true };
        case ROLE_LIST_SUCCESS:
            return { ...state, Role: action.payload, listloading: false, error: null };
        case ROLE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ROLE_ADD:
            return { ...state, loading: true };
        case ROLE_ADD_SUCCESS:
            return { ...state, RoleAdd: action.payload, loading: false, error: null };
        case ROLE_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ROLE_UPDATE:
            return { ...state, loading: true };
        case ROLE_UPDATE_SUCCESS:
            return { ...state, RoleUpdate: action.payload, loading: false, error: null };
        case ROLE_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ROLE_DELETE:
            return { ...state, loading: true };
        case ROLE_DELETE_SUCCESS:
            return { ...state, RoleDelete: action.payload, loading: false, error: null };
        case ROLE_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Role;
