// @flow
import {
    PAY_DETAILS_LIST,
    PAYROLL_DETAILS_LIST_SUCCESS,
    PAYROLL_DETAILS_LIST_FAILED,
    PAY_DETAILS_ADD,
    PAY_DETAILS_ADD_SUCCESS,
    PAY_DETAILS_ADD_FAILED,
    PAY_UPDATE,
    PAY_UPDATE_SUCCESS,
    PAY_UPDATE_FAILED,
    PAY_DETAILS_DELETE,
    PAY_DETAILS_DELETE_SUCCESS,
    PAY_DETAILS_DELETE_FAILED,
    SALARY_DETAILS_FETCH,
    SALARY_DETAILS_FETCH_SUCCESS,
    SALARY_DETAILS_FETCH_FAILED,

} from './constants';

type PayDetailsAction = { type: string, payload: {} | string };

export const getPayDetailsListData = (data:{}): PayDetailsAction => ({
    type: PAY_DETAILS_LIST,
    payload: data,
});

export const getPayDetailsListDataSuccess = (PayDetails: string): PayDetailsAction => ({
    type: PAYROLL_DETAILS_LIST_SUCCESS,
    payload: PayDetails,
});

export const getPayDetailsListDataFailed = (error: string): PayDetailsAction => ({
    type: PAYROLL_DETAILS_LIST_FAILED,
    payload: error,
});

export const getPayAdd = (data:{}): PayDetailsAction => ({
    type: PAY_DETAILS_ADD,
    payload: data,
});

export const getPayAddSuccess = (PayAdd: string): PayDetailsAction => ({
    type: PAY_DETAILS_ADD_SUCCESS,
    payload: PayAdd,
});

export const getPayAddFailed = (error: string): PayDetailsAction => ({
    type: PAY_DETAILS_ADD_FAILED,
    payload: error,
});

export const getPayUpdate = (data:{}): PayDetailsAction => ({
    type: PAY_UPDATE,
    payload: data,
});

export const getPayUpdateSuccess = (PayUpdate: string): PayDetailsAction => ({
    type: PAY_UPDATE_SUCCESS,
    payload: PayUpdate,
});

export const getPayUpdateFailed = (error: string): PayDetailsAction => ({
    type:PAY_UPDATE_FAILED,
    payload: error,
});

export const getPayDelete = (id): PayDetailsAction => ({
    type: PAY_DETAILS_DELETE,
    payload: id,
});

export const getPayDeleteSuccess = (PayDelete: string): PayDetailsAction => ({
    type: PAY_DETAILS_DELETE_SUCCESS,
    payload: PayDelete,
});

export const getPayDeleteFailed = (error: string): PayDetailsAction => ({
    type: PAY_DETAILS_DELETE_FAILED,
    payload: error,
});

//SALARY

export const getFetchSalarySuccess = (salaryFetch: string): PayDetailsAction => ({
    type: SALARY_DETAILS_FETCH_SUCCESS,
    payload: salaryFetch,
});

export const getFetchSalaryFailed = (error: string): PayDetailsAction => ({
    type: SALARY_DETAILS_FETCH_FAILED,
    payload: error,
});

export const getFetchSalary = (data:{}): PayDetailsAction => ({
    type: SALARY_DETAILS_FETCH,
    payload: data,
});