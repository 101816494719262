/* MONTHLYACTIVITY */
export const MONTHLYACTIVITY_LIST = 'MONTHLYACTIVITY_LIST';
export const MONTHLYACTIVITY_LIST_SUCCESS = 'MONTHLYACTIVITY_LIST_SUCCESS';
export const MONTHLYACTIVITY_LIST_FAILED = 'MONTHLYACTIVITY_LIST_FAILED';

export const MONTHLYACTIVITY_ADD = 'MONTHLYACTIVITY_ADD';
export const MONTHLYACTIVITY_ADD_SUCCESS = 'MONTHLYACTIVITY_ADD_SUCCESS';
export const MONTHLYACTIVITY_ADD_FAILED = 'MONTHLYACTIVITY_ADD_FAILED';

export const MONTHLYJOBDES_ADD = 'MONTHLYJOBDES_ADD';
export const MONTHLYJOBDES_ADD_SUCCESS = 'MONTHLYJOBDES_ADD_SUCCESS';
export const MONTHLYJOBDES_ADD_FAILED = 'MONTHLYJOBDES_ADD_FAILED';

export const MONTHLYACTIVITY_UPDATE = 'MONTHLYACTIVITY_UPDATE';
export const MONTHLYACTIVITY_UPDATE_SUCCESS = 'MONTHLYACTIVITY_UPDATE_SUCCESS';
export const MONTHLYACTIVITY_UPDATE_FAILED = 'MONTHLYACTIVITY_UPDATE_FAILED';

export const MONTHLYACTIVITY_DELETE = 'MONTHLYACTIVITY_DELETE';
export const MONTHLYACTIVITY_DELETE_SUCCESS = 'MONTHLYACTIVITY_DELETE_SUCCESS';
export const MONTHLYACTIVITY_DELETE_FAILED = 'MONTHLYACTIVITYADD_DELETE_FAILED';
