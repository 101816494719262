/*  REMAINDER */
export const  REMAINDER_LIST =  'REMAINDER_LIST';
export const  REMAINDER_LIST_SUCCESS =  'REMAINDER_LIST_SUCCESS';
export const  REMAINDER_LIST_FAILED =  'REMAINDER_LIST_FAILED';

export const  REMAINDER_ADD =  'REMAINDER_ADD';
export const  REMAINDER_ADD_SUCCESS =  'REMAINDER_ADD_SUCCESS';
export const  REMAINDER_ADD_FAILED =  'REMAINDER_ADD_FAILED';

export const  REMAINDER_UPDATE =  'REMAINDER_UPDATE';
export const  REMAINDER_UPDATE_SUCCESS =  'REMAINDER_UPDATE_SUCCESS';
export const  REMAINDER_UPDATE_FAILED =  'REMAINDER_UPDATE_FAILED';

export const  REMAINDER_DELETE =  'REMAINDER_DELETE';
export const  REMAINDER_DELETE_SUCCESS =  'REMAINDER_DELETE_SUCCESS';
export const  REMAINDER_DELETE_FAILED =  'REMAINDER_DELETE_FAILED';
