// @flow
import {
    ROLE_LIST,
    ROLE_LIST_SUCCESS,
    ROLE_LIST_FAILED,
    ROLE_ADD,
    ROLE_ADD_SUCCESS,
    ROLE_ADD_FAILED,
    ROLE_UPDATE,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_FAILED,
    ROLE_DELETE,
    ROLE_DELETE_SUCCESS,
    ROLE_DELETE_FAILED
} from './constants';

type RoleAction = { type: string, payload: {} | string };

export const getRoleList = (): RoleAction => ({
    type: ROLE_LIST,
    payload: {},
});

export const getRoleListSuccess = (Role: string): RoleAction => ({
    type: ROLE_LIST_SUCCESS,
    payload: Role,
});

export const getRoleListFailed = (error: string): RoleAction => ({
    type: ROLE_LIST_FAILED,
    payload: error,
});

export const getRoleAdd = (data:{}): RoleAction => ({
    type: ROLE_ADD,
    payload: data,
});

export const getRoleAddSuccess = (RoleAdd: string): RoleAction => ({
    type: ROLE_ADD_SUCCESS,
    payload: RoleAdd,
});

export const getRoleAddFailed = (error: string): RoleAction => ({
    type: ROLE_ADD_FAILED,
    payload: error,
});

export const getRoleUpdate = (data:{}): RoleAction => ({
    type: ROLE_UPDATE,
    payload: data,
});

export const getRoleUpdateSuccess = (RoleUpdate: string): RoleAction => ({
    type: ROLE_UPDATE_SUCCESS,
    payload: RoleUpdate,
});

export const getRoleUpdateFailed = (error: string): RoleAction => ({
    type:ROLE_UPDATE_FAILED,
    payload: error,
});

export const getRoleDelete = (id): RoleAction => ({
    type: ROLE_DELETE,
    payload: id,
});

export const getRoleDeleteSuccess = (RoleDelete: string): RoleAction => ({
    type: ROLE_DELETE_SUCCESS,
    payload: RoleDelete,
});

export const getRoleDeleteFailed = (error: string): RoleAction => ({
    type: ROLE_DELETE_FAILED,
    payload: error,
});
