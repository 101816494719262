/* TEAM */
export const TEAM_LIST = 'TEAM_LIST';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const TEAM_LIST_FAILED = 'TEAM_LIST_FAILED';

export const TEAM_ADD = 'TEAM_ADD';
export const TEAM_ADD_SUCCESS = 'TEAM_ADD_SUCCESS';
export const TEAM_ADD_FAILED = 'TEAMADD_ADD_FAILED';

export const TEAM_UPDATE = 'TEAM_UPDATE';
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS';
export const TEAM_UPDATE_FAILED = 'TEAM_UPDATE_FAILED';

export const TEAM_DELETE = 'TEAM_DELETE';
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS';
export const TEAM_DELETE_FAILED = 'TEAMADD_DELETE_FAILED';
