/* INVENTORY */
export const INVENTORY_LIST = 'INVENTORY_LIST';
export const INVENTORY_LIST_SUCCESS = 'INVENTORY_LIST_SUCCESS';
export const INVENTORY_LIST_FAILED = 'INVENTORY_LIST_FAILED';

export const INVENTORY_ADD = 'INVENTORY_ADD';
export const INVENTORY_ADD_SUCCESS = 'INVENTORY_ADD_SUCCESS';
export const INVENTORY_ADD_FAILED = 'INVENTORYADD_ADD_FAILED';

export const INVENTORY_UPDATE = 'INVENTORY_UPDATE';
export const INVENTORY_UPDATE_SUCCESS = 'INVENTORY_UPDATE_SUCCESS';
export const INVENTORY_UPDATE_FAILED = 'INVENTORY_UPDATE_FAILED';

export const INVENTORY_DELETE = 'INVENTORY_DELETE';
export const INVENTORY_DELETE_SUCCESS = 'INVENTORY_DELETE_SUCCESS';
export const INVENTORY_DELETE_FAILED = 'INVENTORYADD_DELETE_FAILED';
