/* TEAM */
export const CLIENT_LIST = 'CLIENT_LIST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAILED = 'CLIENT_LIST_FAILED';

export const CLIENT_ADD = 'CLIENT_ADD';
export const CLIENT_ADD_SUCCESS = 'CLIENT_ADD_SUCCESS';
export const CLIENT_ADD_FAILED = 'TEAMADD_ADD_FAILED';

export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_FAILED = 'CLIENT_UPDATE_FAILED';

export const CLIENT_DELETE = 'CLIENT_DELETE';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAILED = 'TEAMADD_DELETE_FAILED';
