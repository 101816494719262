// @flow
import {
    USERHASH_LIST,
    USERHASH_LIST_SUCCESS,
    USERHASH_LIST_FAILED,
    USERHASH_SHOW,
    USERHASH_SHOW_SUCCESS,
    USERHASH_SHOW_FAILED,
    TOPICHASH_SHOW,
    TOPICHASH_SHOW_SUCCESS,
    TOPICHASH_SHOW_FAILED,
    
    DEPTEAM_LIST,
    DEPTEAM_LIST_SUCCESS,
    DEPTEAM_LIST_FAILED,
    USERHASH_ADD,
    USERHASH_ADD_SUCCESS,
    USERHASH_ADD_FAILED,
    USERTOPICHASH_UPDATE,
    USERTOPICHASH_UPDATE_SUCCESS,
    USERTOPICHASH_UPDATE_FAILED,
    
    USERSUBHASH_ADD,
    USERSUBHASH_ADD_SUCCESS,
    USERSUBHASH_ADD_FAILED,


    USERSUBTOPICHASH_UPDATE,
    USERSUBTOPICHASH_UPDATE_SUCCESS,
    USERSUBTOPICHASH_UPDATE_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type UserhashAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Userhash = (state: State = INIT_STATE, action:UserhashAction) => {
    switch (action.type) {
        case USERHASH_LIST:
            return { ...state, listloading: true };
        case USERHASH_LIST_SUCCESS:
            return { ...state, Userhash: action.payload, listloading: false, error: null };
        case USERHASH_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
            case USERHASH_SHOW:
                return { ...state, listloading: true };
            case USERHASH_SHOW_SUCCESS:
                return { ...state, Userhashdata: action.payload, listloading: false, error: null };
            case USERHASH_SHOW_FAILED:
                return { ...state, error: action.payload, loading: false };



                case  TOPICHASH_SHOW:
                    return { ...state, listloading: true };
                case  TOPICHASH_SHOW_SUCCESS:
                    return { ...state, Topichashdata: action.payload, listloading: false, error: null };
                case  TOPICHASH_SHOW_FAILED:
                    return { ...state, error: action.payload, loading: false };


                    case  DEPTEAM_LIST:
                        return { ...state, listloading: true };
                    case  DEPTEAM_LIST_SUCCESS:
                        return { ...state, depteamdata: action.payload, listloading: false, error: null };
                    case  DEPTEAM_LIST_FAILED:
                        return { ...state, error: action.payload, loading: false };
    

                        case USERHASH_ADD:
                            return { ...state, loading: true };
                        case USERHASH_ADD_SUCCESS:
                            return { ...state, UserhashAdd: action.payload, loading: false, error: null };
                        case USERHASH_ADD_FAILED:
                            return { ...state, error: action.payload, loading: false };


                            case  USERTOPICHASH_UPDATE:
                                return { ...state, loading: true };
                            case  USERTOPICHASH_UPDATE_SUCCESS:
                                return { ...state,UserTopichashUpdate: action.payload, loading: false, error: null };
                            case  USERTOPICHASH_UPDATE_FAILED:
                                return { ...state, error: action.payload, loading: false };

////////////////////////

                                case USERSUBHASH_ADD:
                                    return { ...state, loading: true };
                                case USERSUBHASH_ADD_SUCCESS:
                                    return { ...state, UserSubhashAdd: action.payload, loading: false, error: null };
                                case USERSUBHASH_ADD_FAILED:
                                    return { ...state, error: action.payload, loading: false };
        
        
                                    case  USERSUBTOPICHASH_UPDATE:
                                        return { ...state, loading: true };
                                    case  USERSUBTOPICHASH_UPDATE_SUCCESS:
                                        return { ...state,UserSubTopichashUpdate: action.payload, loading: false, error: null };
                                    case  USERSUBTOPICHASH_UPDATE_FAILED:
                                        return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
};

export default Userhash;
