/* HASHBOOK */
export const HASHBOOK_LIST = "HASHBOOK_LIST";
export const HASHBOOK_LIST_SUCCESS = "HASHBOOK_LIST_SUCCESS";
export const HASHBOOK_LIST_FAILED = "HASHBOOK_LIST_FAILED";

export const HASHBOOK_ADD = "HASHBOOK_ADD";
export const HASHBOOK_ADD_SUCCESS = "HASHBOOK_ADD_SUCCESS";
export const HASHBOOK_ADD_FAILED = "HASHBOOKADD_ADD_FAILED";

export const HASHBOOK_UPDATE = "HASHBOOK_UPDATE";
export const HASHBOOK_UPDATE_SUCCESS = "HASHBOOK_UPDATE_SUCCESS";
export const HASHBOOK_UPDATE_FAILED = "HASHBOOK_UPDATE_FAILED";

export const HASHBOOK_DELETE = "HASHBOOK_DELETE";
export const HASHBOOK_DELETE_SUCCESS = "HASHBOOK_DELETE_SUCCESS";
export const HASHBOOK_DELETE_FAILED = "HASHBOOKADD_DELETE_FAILED";

export const HASHBOOK_AUTHOR = "HASHBOOK_AUTHOR";
export const HASHBOOK_AUTHOR_SUCCESS = "HASHBOOK_AUTHOR_SUCCESS";
export const HASHBOOK_AUTHOR_FAILED = "HASHBOOK_AUTHOR_FAILED";

export const HASHBOOK_CREATE_COMMENT = "HASHBOOK_CREATE_COMMENT";
export const HASHBOOK_CREATE_COMMENT_SUCCESS =
  "HASHBOOK_CREATE_COMMENT_SUCCESS";
export const HASHBOOK_CREATE_COMMENT_FAILED = "HASHBOOK_CREATE_COMMENT_FAILED";

export const HASHBOOK_COMMENT_DETAILS = "HASHBOOK_COMMENT_DETAILS";
export const HASHBOOK_COMMENT_DETAILS_SUCCESS =
  "HASHBOOK_COMMENT_DETAILS_SUCCESS";
export const HASHBOOK_COMMENT_DETAILS_FAILED = "HASHBOOK_CREATE_COMMENT_FAILED";

export const HASHBOOK_SUBTOPIC = "HASHBOOK_SUBTOPIC";
export const HASHBOOK_SUBTOPIC_SUCCESS = "HASHBOOK_SUBTOPIC_SUCCESS";
export const HASHBOOK_SUBTOPIC_FAILED = "HASHBOOK_SUBTOPIC_FAILED";
