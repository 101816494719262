/* MYTASK */
export const MYTASK_LIST = "MYTASK_LIST";
export const MYTASK_LIST_SUCCESS = "MYTASK_LIST_SUCCESS";
export const MYTASK_LIST_FAILED = "MYTASK_LIST_FAILED";

export const MYTASK_ADD = "MYTASK_ADD";
export const MYTASK_ADD_SUCCESS = "MYTASK_ADD_SUCCESS";
export const MYTASK_ADD_FAILED = "MYTASKADD_ADD_FAILED";

export const MYTASK_COMMENT_ADD = "MYTASK_COMMENT_ADD";
export const MYTASK_COMMENT_ADD_SUCCESS = "MYTASK_COMMENT_ADD_SUCCESS";
export const MYTASK_COMMENT_ADD_FAILED = "MYTASK_COMMENT_ADD_FAILED";

export const MYTASK_UPDATE = "MYTASK_UPDATE";
export const MYTASK_UPDATE_SUCCESS = "MYTASK_UPDATE_SUCCESS";
export const MYTASK_UPDATE_FAILED = "MYTASK_UPDATE_FAILED";

export const MYTASK_DELETE = "MYTASK_DELETE";
export const MYTASK_DELETE_SUCCESS = "MYTASK_DELETE_SUCCESS";
export const MYTASK_DELETE_FAILED = "MYTASKADD_DELETE_FAILED";
