// @flow
import {
    ONBOARD_LIST,
    ONBOARD_LIST_SUCCESS,
    ONBOARD_LIST_FAILED,
    ONBOARD_ADD,
    ONBOARD_ADD_SUCCESS,
    ONBOARD_ADD_FAILED,
    ONBOARD_UPDATE,
    ONBOARD_UPDATE_SUCCESS,
    ONBOARD_UPDATE_FAILED,
    ONBOARD_DELETE,
    ONBOARD_DELETE_SUCCESS,
    ONBOARD_DELETE_FAILED,
    ONBOARD_LISTVIEW,
    ONBOARD_LISTVIEW_SUCCESS,
    ONBOARD_LISTVIEW_FAILED,
    ONBOARD_INVITE,
    ONBOARD_INVITE_SUCCESS,
    ONBOARD_INVITE_FAILED
} from './constants';

type OnboardAction = { type: string, payload: {} | string };

export const getOnboardList = (): OnboardAction => ({
    type: ONBOARD_LIST,
    payload: {},
});

export const getOnboardListSuccess = (onboard: string): OnboardAction => ({
    type: ONBOARD_LIST_SUCCESS,
    payload: onboard,
});

export const getOnboardListFailed = (error: string): OnboardAction => ({
    type: ONBOARD_LIST_FAILED,
    payload: error,
});

export const getOnboardAdd = (data:{}): OnboardAction => ({
    type: ONBOARD_ADD,
    payload: data,
});

export const getOnboardAddSuccess = (onboardAdd: string): OnboardAction => ({
    type: ONBOARD_ADD_SUCCESS,
    payload: onboardAdd,
});

export const getOnboardAddFailed = (error: string): OnboardAction => ({
    type: ONBOARD_ADD_FAILED,
    payload: error,
});

export const getOnboardUpdate = (data:{}): OnboardAction => ({
    type: ONBOARD_UPDATE,
    payload: data,
});

export const getOnboardUpdateSuccess = (onboardUpdate: string): OnboardAction => ({
    type: ONBOARD_UPDATE_SUCCESS,
    payload: onboardUpdate,
});

export const getOnboardUpdateFailed = (error: string): OnboardAction => ({
    type: ONBOARD_UPDATE_FAILED,
    payload: error,
});

export const getOnboardDelete = (id): OnboardAction => ({
    type: ONBOARD_DELETE,
    payload: id,
});

export const getOnboardDeleteSuccess = (onboardDelete: string): OnboardAction => ({
    type: ONBOARD_DELETE_SUCCESS,
    payload: onboardDelete,
});

export const getOnboardDeleteFailed = (error: string): OnboardAction => ({
    type: ONBOARD_DELETE_FAILED,
    payload: error,
});
//
export const getOnboardListView = (data:{}): OnboardAction => ({
    type: ONBOARD_LISTVIEW,
    payload: data,
});

export const getOnboardListViewSuccess = (onboardView: string): OnboardAction => ({
    type: ONBOARD_LISTVIEW_SUCCESS,
    payload: onboardView,
});

export const getOnboardListViewFailed = (error: string): OnboardAction => ({
    type: ONBOARD_LISTVIEW_FAILED,
    payload: error,
});



export const getOnboardInvite = (id): OnboardAction => ({
    type: ONBOARD_INVITE,
    payload: id,
});

export const getOnboardInviteSuccess = (onboardInvite: string): OnboardAction => ({
    type: ONBOARD_INVITE_SUCCESS,
    payload: onboardInvite,
});

export const getOnboardInviteFailed = (error: string): OnboardAction => ({
    type: ONBOARD_INVITE_FAILED,
    payload: error,
});