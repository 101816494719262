/* TEAM */
export const USERHASH_LIST = 'USERHASH_LIST';
export const USERHASH_LIST_SUCCESS = 'USERHASH_LIST_SUCCESS';
export const USERHASH_LIST_FAILED = 'USERHASH_LIST_FAILED';

export const USERHASH_SHOW = 'USERHASH_SHOW';
export const USERHASH_SHOW_SUCCESS = 'USERHASH_SHOW_SUCCESS';
export const USERHASH_SHOW_FAILED = 'USERHASH_SHOW_FAILED';


export const TOPICHASH_SHOW = 'TOPICHASH_SHOW';
export const TOPICHASH_SHOW_SUCCESS = 'TOPICHASH_SHOW_SUCCESS';
export const TOPICHASH_SHOW_FAILED = 'TOPICHASH_SHOW_FAILED';




export const DEPTEAM_LIST = 'DEPTEAM_LIST';
export const DEPTEAM_LIST_SUCCESS = 'DEPTEAM_LIST_SUCCESS';
export const DEPTEAM_LIST_FAILED = 'DEPTEAM_LIST_FAILED';




export const USERHASH_ADD='USERHASH_ADD';
export const USERHASH_ADD_SUCCESS='USERHASH_ADD_SUCCESS';
export const USERHASH_ADD_FAILED='USERHASH_ADD_FAILED';




export const USERTOPICHASH_UPDATE='USERTOPICHASH_UPDATE';
export const USERTOPICHASH_UPDATE_SUCCESS='USERTOPICHASH_UPDATE_SUCCESS';
export const USERTOPICHASH_UPDATE_FAILED='USERTOPICHASH_UPDATE_FAILED';



export const USERSUBHASH_ADD='USERSUBHASH_ADD';
export const USERSUBHASH_ADD_SUCCESS='USERSUBHASH_ADD_SUCCESS';
export const USERSUBHASH_ADD_FAILED ='USERSUBHASH_ADD_FAILED';


export const USERSUBTOPICHASH_UPDATE ='USERSUBTOPICHASH_UPDATE';
export const USERSUBTOPICHASH_UPDATE_SUCCESS ='USERSUBTOPICHASH_UPDATE_SUCCESS';
export const USERSUBTOPICHASH_UPDATE_FAILED ='USERSUBTOPICHASH_UPDATE_FAILED';