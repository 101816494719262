/* WEEKLYACTIVITY */
export const WEEKLYACTIVITY_LIST = 'WEEKLYACTIVITY_LIST';
export const WEEKLYACTIVITY_LIST_SUCCESS = 'WEEKLYACTIVITY_LIST_SUCCESS';
export const WEEKLYACTIVITY_LIST_FAILED = 'WEEKLYACTIVITY_LIST_FAILED';

export const WEEKLYACTIVITY_ADD = 'WEEKLYACTIVITY_ADD';
export const WEEKLYACTIVITY_ADD_SUCCESS = 'WEEKLYACTIVITY_ADD_SUCCESS';
export const WEEKLYACTIVITY_ADD_FAILED = 'WEEKLYACTIVITY_ADD_FAILED';

export const WEEKLYJOBDES_ADD = 'WEEKLYJOBDES_ADD';
export const WEEKLYJOBDES_ADD_SUCCESS = 'WEEKLYJOBDES_ADD_SUCCESS';
export const WEEKLYJOBDES_ADD_FAILED = 'WEEKLYJOBDES_ADD_FAILED';

export const WEEKLYACTIVITY_UPDATE = 'WEEKLYACTIVITY_UPDATE';
export const WEEKLYACTIVITY_UPDATE_SUCCESS = 'WEEKLYACTIVITY_UPDATE_SUCCESS';
export const WEEKLYACTIVITY_UPDATE_FAILED = 'WEEKLYACTIVITY_UPDATE_FAILED';

export const WEEKLYACTIVITY_DELETE = 'WEEKLYACTIVITY_DELETE';
export const WEEKLYACTIVITY_DELETE_SUCCESS = 'WEEKLYACTIVITY_DELETE_SUCCESS';
export const WEEKLYACTIVITY_DELETE_FAILED = 'WEEKLYACTIVITYADD_DELETE_FAILED';
