// @flow
import {
    CLIENT_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_LIST_FAILED,
    CLIENT_ADD,
    CLIENT_ADD_SUCCESS,
    CLIENT_ADD_FAILED,
    CLIENT_UPDATE,
    CLIENT_UPDATE_SUCCESS,
    CLIENT_UPDATE_FAILED,
    CLIENT_DELETE,
    CLIENT_DELETE_SUCCESS,
    CLIENT_DELETE_FAILED
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type ClientAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Client = (state: State = INIT_STATE, action: ClientAction) => {
    switch (action.type) {
        case CLIENT_LIST:
            return { ...state, listloading: true };
        case CLIENT_LIST_SUCCESS:
            return { ...state, client: action.payload, listloading: false, error: null };
        case CLIENT_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLIENT_ADD:
            return { ...state, loading: true };
        case CLIENT_ADD_SUCCESS:
            return { ...state, clientAdd: action.payload, loading: false, error: null };
        case CLIENT_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLIENT_UPDATE:
            return { ...state, loading: true };
        case CLIENT_UPDATE_SUCCESS:
            return { ...state, clientUpdate: action.payload, loading: false, error: null };
        case CLIENT_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLIENT_DELETE:
            return { ...state, loading: true };
        case CLIENT_DELETE_SUCCESS:
            return { ...state, clientDelete: action.payload, loading: false, error: null };
        case CLIENT_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Client;
