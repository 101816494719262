
export const CLIENT_REVENUE_LIST='CLIENT_REVENUE_LIST';
export const CLIENT_REVENUE_LIST_SUCCESS='CLIENT_REVENUE_LIST_SUCCESS';
export const CLIENT_REVENUE_LIST_FAILED='CLIENT_REVENUE_LIST_FAILED';

export const CLIENT_REVENUE_ADD='CLIENT_REVENUE_ADD';
export const CLIENT_REVENUE_ADD_SUCCESS='CLIENT_REVENUE_ADD_SUCCESS';
export const CLIENT_REVENUE_ADD_FAILED='CLIENT_REVENUE_ADD_FAILED';

export const CLIENT_REVENUE_UPDATE='CLIENT_REVENUE_UPDATE';
export const CLIENT_REVENUE_UPDATE_SUCCESS='CLIENT_REVENUE_UPDATE_SUCCESS';
export const CLIENT_REVENUE_UPDATE_FAILED='CLIENT_REVENUE_UPDATE_FAILED';

export const CLIENT_REVENUE_DELETE='CLIENT_REVENUE_DELETE';
export const CLIENT_REVENUE_DELETE_SUCCESS='CLIENT_REVENUE_DELETE_SUCCESS';
export const CLIENT_REVENUE_DELETE_FAILED='CLIENT_REVENUE_DELETE_FAILED';

export const COUNTRY_LIST='COUNTRY_LIST';
export const COUNTRY_LIST_SUCCESS='COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAILED='COUNTRY_LIST_FAILED';


