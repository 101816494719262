/* REPORTMONTH */
export const REPORTMONTH_LIST = 'REPORTMONTH_LIST';
export const REPORTMONTH_LIST_SUCCESS = 'REPORTMONTH_LIST_SUCCESS';
export const REPORTMONTH_LIST_FAILED = 'REPORTMONTH_LIST_FAILED';




/* REPORT */
export const REPORT_LIST = 'REPORT_LIST';
export const REPORT_LIST_SUCCESS = 'REPORT_LIST_SUCCESS';
export const REPORT_LIST_FAILED = 'REPORT_LIST_FAILED';